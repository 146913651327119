import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { theme } from '@lawcpd/learner/shared/ui'
import LearnerTable, { LearnerTableHeaders } from './data/learner-table';

const StyledTable = styled.div`
  position: relative;

  .learners-table {
    border: none;
    border-right: solid 1px #DDEFEF;
    border-collapse: separate;
    border-spacing: 0;
    font: normal 13px Arial, sans-serif;
  }

  .learners-table thead th {
    background-color: #DDEFEF;
    border: none;
    color: #336B6B;
    padding: 10px;
    text-align: left;
    text-shadow: 1px 1px 1px #fff;
    white-space: nowrap;
  }
  .learners-table tbody td {
    border-bottom: solid 1px #DDEFEF;
    color: #333;
    padding: 10px;
    text-shadow: 1px 1px 1px #fff;
    white-space: nowrap;
    height: 30px;
    vertical-align: top;
  }

  .tablecontainer {
    margin-left: 141px;
    overflow-x: scroll;
    overflow-y: visible;
    padding-bottom: 5px;
    width:70vw;
  }

  .learners-table .sticky-col {
    border-left: solid 1px #DDEFEF;
    border-right: solid 1px #DDEFEF;
    left: 0;
    position: absolute;
    top: auto;
    width: 120px;
    white-space: break-spaces;
  }

  @media only screen and ${theme.notLargeDevice} {
    .tablecontainer {
      width:90vw;
    }
  }
`;

const StlyledController = styled.div`
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;

  button {
    margin-right: 10px;
    cursor: pointer;
  }
`
export const Dashboard = (props) => {

  return (
    <div>
      <LearnerTable
          learnerHeaders={[
            LearnerTableHeaders.Name,
            LearnerTableHeaders.Email,
            LearnerTableHeaders.Created,
            LearnerTableHeaders.Magento,
            LearnerTableHeaders.Auth0,
            LearnerTableHeaders.Login,
            LearnerTableHeaders.Information,
            LearnerTableHeaders.Masquerade,
            LearnerTableHeaders.Registration,
            LearnerTableHeaders.LearnerEventLogs
          ]}
        />
    </div>
  )
}

export default Dashboard;
