
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { ModalBackdrop, ModalPromptTypes } from '@lawcpd/learner/shared/ui';
import { useEffect, useState } from 'react';
import { useFirebaseAuth } from 'support-provider';
import styled from 'styled-components';


const ReportsTable = styled.table`
  border: solid 1px #DDEEEE;
  border-collapse: collapse;
  border-spacing: 0;
  font: normal 13px Arial, sans-serif;

  thead th {
    background-color: #DDEFEF;
    border: solid 1px #DDEEEE;
    color: #336B6B;
    padding: 10px;
    text-align: left;
    text-shadow: 1px 1px 1px #fff;
  }

  tbody td {
    border: solid 1px #DDEEEE;
    color: #333;
    padding: 10px;
    text-shadow: 1px 1px 1px #fff;
  }
`;

interface IGeneratedReportsProps {
  onCancel: () => void;
}

const Reports = (report: string, key: string | number, handler: (r: string) => void) => {
  const parts = report.split("_");

  const mapping = {
    startDate: null,
    endDate: null,
    type: null,
    year: null,
  };

  function subPart(p: string, delimiter: string): string {
    if(!p) return null;

    const subPart = p.split(delimiter);

    return subPart && subPart[1] ? subPart[1] : null;
  }

  for(const p of parts){
    if(p.includes("f-")){ // Start date
      mapping.startDate = subPart(p, "f-");
    }
    else if(p.includes("t-")){ // End date
      mapping.endDate = subPart(p, "t-");
    }
    else if(p.includes("type-")){ // Type
      mapping.type = subPart(p, "type-")
    }
    else if(p.includes("y-")){ // Year
      mapping.year = subPart(p, "y-");
    }
  }

  return (
  <tr key={key}>
    <td><a onClick={() => handler(report)}>{report}</a></td>
    <td>{mapping.startDate || ""}</td>
    <td>{mapping.endDate || ""}</td>
    <td>{mapping.type || ""}</td>
    <td>{mapping.year || ""}</td>
  </tr>
  )
}

export const GeneratedReports = (props: IGeneratedReportsProps) => {
  const
    { onCancel } = props,
    { user } = useFirebaseAuth(),
    { cookiesDomain, mycpd: { api } } = useEnvironment(),
    [ reports, setReports ] = useState<string[]>([]),
    reportEndPoint = `${api}/api/registration/report/generated`;

  useEffect(() => {
    loadReportsHandler();
  }, [])

  const loadReportsHandler = () => {
    user.getIdToken()
    .then((token) => {
      return fetch(reportEndPoint, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    })
    .then((res) => { console.log(res);
     return res.json()
    })
    .then((data) => {
      setReports(data);
    })
    .catch((e) => {
      console.error(e);
      setReports([]);
    });
  };

  const preSignedUrlHandler = (jobId: string) => {

    user.getIdToken()
    .then((token) => {
      return fetch(`${reportEndPoint}/${jobId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    })
    .then((res) => res.json())
    .then((data) => {
      const url = data && data[0];

      window.open(url, "_blank").focus();
    })
    .catch((e) => {
      console.error(e);

    })
  }

  return(
    <ModalBackdrop
      backdropId='backdrop-root'
      overlayId='overlay-root'
      type={('prompt' as ModalPromptTypes)}
      title='List of generated reports'
      onConfirm={onCancel}
      onClose={onCancel}
      confirmText='Okay'
    >
      <button onClick={loadReportsHandler}>Refresh list</button>
      <ReportsTable>
        <thead>
          <tr>
            <th>File name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Report Type</th>
            <th>Report Year</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((r, i) => {
            return Reports(r, i, preSignedUrlHandler);
          })}
        </tbody>
      </ReportsTable>
    </ModalBackdrop>
  )
}
