import SpanPill from '../ui/span-pill';
import styled from "styled-components";
import { CoursesSelection } from './drop-down-courses';
import { RegistrationsSelection } from './drop-down-registrations';
import { StyledRefinementsAndSelection } from './learner-search';
import { AnnotatedRegistration, IoRegistration } from '@lawcpd/learner/shared/data';
import DropDownSelect, { OptionsType } from '../ui/drop-down-select';

export const StyledLearnerFormWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .learner-form-row {
    flex: 1;
    display: flex;
    flex-direction: row
  }

  .learner-form-row > * {
    flex: 0 2 auto;
  }

  .learner-form-row > span {
    align-self: stretch;
    width: 1px;
    background-color: hsl(0, 0%, 80%);
    margin: 8px;
    box-sizing: border-box;
  }

  .learner-form-row label{
    justify-self:stretch;
    word-break: break-all;
    text-align: right;
    flex-shrink: 0;
  }

  .learner-form-row button{
    cursor: pointer;
  }

  .learner-form-row .selected-pills{
    text-overflow: ellipsis;
    border:solid 1px black;
  }
`

/**
 *
 */
interface LearnersComponentSelectionProp {
  learnerId: string | number;
  learnerEmail: string;
  learnerName: string;
  assignHandler: (i: string | number, c: string[]) => void;
  assignmentsFromLearners: string[]; // List of assignments' course ids from Learners to avoid conflict and duplication when allocating
}

export const LearnersComponentSelection = (props: LearnersComponentSelectionProp) => {
  const {
    learnerId, learnerName, learnerEmail,
    assignmentsFromLearners, assignHandler
    } = props;

  const addCourseSelected = (selected: OptionsType<string>[]) => {
    const coursesNotAlreadyAssignedFromLearners = selected.filter((s) => {
      return !assignmentsFromLearners.includes(s.value);
    }).map((s) => s.value);

    if(!coursesNotAlreadyAssignedFromLearners.length) return;
    assignHandler(learnerId, coursesNotAlreadyAssignedFromLearners)
  }

  return (
    <div className={'learner-form-row'} key={learnerId}>
      <label>{`${learnerName} (${learnerEmail})`}</label>
      <span></span>
      <CoursesSelection
        id={`course-selection-${learnerId}`}
        isPurchasable={ true }
        onChange={addCourseSelected}
      />
    </div>
  )
}


interface LearnersRemoveComponentSelectionProp extends LearnersComponentSelectionProp {
  learnerRegistrations: AnnotatedRegistration[];
}

export const LearnersRemoveComponentSelection = (props: LearnersRemoveComponentSelectionProp) => {
  const {
      learnerId, learnerName, learnerEmail,
      learnerRegistrations, assignmentsFromLearners, assignHandler
    } = props,
    registrations = learnerRegistrations.filter((r) => {
      return (
        r.learnerId === learnerId
        && !assignmentsFromLearners.includes(r.id)
      );
    });

  const addRegistrationSelected = (selected: OptionsType<string>[]) => {
    const coursesNotAlreadyAssignedFromLearners = selected.filter((s) => {
      return !assignmentsFromLearners.includes(s.value);
    }).map((s) => s.value);

    if(!coursesNotAlreadyAssignedFromLearners.length) return;
    assignHandler(learnerId, coursesNotAlreadyAssignedFromLearners)
  }

  return (
    <div className={ 'learner-form-row' } key={ learnerId }>
      <label>{ `${learnerName} (${learnerEmail})` }</label>
      <span></span>
      <RegistrationsSelection
        id={ `course-selection-${learnerId}` }
        showStatus={ true }
        registrations={ registrations }
        onChange={addRegistrationSelected}
      />
    </div>
  )
}
