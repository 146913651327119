import { ECompetencyType, IoLearner, MCompetencyType } from "@lawcpd/learner/shared/data";
import { useEnvironment } from "@lawcpd/learner/shared/provider";
import { Button } from "@lawcpd/learner/shared/ui";
import { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { useFirebaseAuth } from "support-provider"
import DropDownSelect, { OptionsType } from "./ui/drop-down-select";

const StyledForms = styled.div`
  .text-box {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    min-height: 38px;
    padding-left: 0.5em;
  }

  .drop-down {
    display: inline-block;
    min-width: 140px;
  }

  .form {
    margin: 0.5em;
  }

  div > * {
    vertical-align: middle;
  }
`;

export const ExternalCpdPage = (props) => {
  const
    learnerIdTypes: OptionsType<string>[] = [
      { value: "lawcpd", label:"ID" },
      { value: "username", label:"Username" },
      { value: "email", label: "Email" },
      { value: "scorm", label: "SCORM ID" },
      { value: "magento", label:"Magento ID" }
    ],
    competencies: OptionsType<string>[] = [
      { value: "eth", label: MCompetencyType[ECompetencyType.Ethics] },
      { value: "psk", label: MCompetencyType[ECompetencyType.ProfessionalSkills] },
      { value: "pma", label: MCompetencyType[ECompetencyType.PracticeManagement] },
      { value: "sub", label: MCompetencyType[ECompetencyType.SubstantiveLaw] },
      { value: "na", label: "Unsassigned" }
    ],
    { user } = useFirebaseAuth(),
    { cookiesDomain, mycpd: { api } } = useEnvironment(),
    [learnerId, setLearnerId] = useState(""),
    [learnerIdType, setLearnerIdType] = useState("lawcpd"),
    [completedDate, setCompletedDate] = useState<Date>(),
    [competency, setCompetency] = useState("eth"),
    [activityName, setActivityName] = useState(""),
    [activityType, setActivityType] = useState(""),
    [cpdPoint, setCpdPoint] = useState(0),
    [cpdProvider, setCpdProvider] = useState("")

  async function createExternalCpdRecord(){
    const isOk = confirm("Are you sure with the following information?");

    if(!isOk) return;

    const
      readLearnerEndpoint = `${api}/api/learner/${learnerId}?type=${learnerIdType}`,
      createExternalCpdEndpoint = `${api}/api/external-cpd`,
      token = await user.getIdToken();

    fetch(readLearnerEndpoint,{
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    })
    .then((response) => response.json())
    .then((data: Record<string, IoLearner>[]) => {

      const
        learner = Object.values(data[0])[0],
        rb = {
          group: "external-cpd",
          name: "create",
          data: {
            learnerId: learner.id,
            data: {
              name: activityName,
              activityCpdType: activityType,
              competency: competency !== "na" ? competency : null,
              completed: completedDate.getTime(),
              cpdPoints: cpdPoint,
              provider: cpdProvider
            }
          }
        };

      if(!learner){ throw new Error("Learner data not found"); }
      return fetch(createExternalCpdEndpoint, {
        method: "POST",
        body: JSON.stringify(rb),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      })
    })
    .then((response) => {
      if(!response.ok){
        throw new Error(`Error creating external cpd record: ${response.statusText}(${response.status})`);
      }
      alert("Successfully created external cpd record");
    })
    .catch((e) => {
      alert(e);
    })
  }

  function learnerIdHandler(e: ChangeEvent<HTMLInputElement>) { setLearnerId(e.target.value); }
  function learnerIdTypeHandler(e: OptionsType<string>) { setLearnerIdType(e.value); }
  function competencyHandler(e: OptionsType<string>) { setCompetency(e.value); }
  function completedHandler(e: ChangeEvent<HTMLInputElement>) { setCompletedDate(new Date(e.target.value)); }
  function activityNameHandler(e: ChangeEvent<HTMLInputElement>) { setActivityName(e.target.value); }
  function activityTypeHandler(e: ChangeEvent<HTMLInputElement>) { setActivityType(e.target.value); }
  function cpdPointHandler(e: ChangeEvent<HTMLInputElement>) { setCpdPoint(+e.target.value); }
  function cpdProviderHandler(e: ChangeEvent<HTMLInputElement>) { setCpdProvider(e.target.value); }

  return (
    <StyledForms>
      <h1>Add a new external CPD record</h1>
      <div className="form">
        <label>Learner: </label>
        <input
          id="learner"
          className="text-box"
          type="text"
          placeholder="Learner ID or email"
          onChange={learnerIdHandler}/>
        <DropDownSelect
          className="drop-down"
          options={learnerIdTypes}
          loading={false}
          startIdx={0}
          onChange={learnerIdTypeHandler}/>
      </div>
      <div className="form">
        <label>Date completed: </label>
        <input
          id="completed-date"
          className="text-box"
          type="date"
          max={new Date().toLocaleDateString("en-CA")}
          onChange={completedHandler}/>
      </div>
      <div className="form">
        <label>Name of Activity: </label>
        <input
          id="cpd-activity"
          className="text-box"
          type="text"
          placeholder="Name of Activity"
          onChange={activityNameHandler}/>
      </div>
      <div className="form">
        <label>Activity CPD Type: </label>
        <input
          id="learner"
          className="text-box"
          type="text"
          placeholder="Type of Activity"
          onChange={activityTypeHandler}/>
      </div>
      <div className="form">
        <label>Competency: </label>
        <DropDownSelect
            className="drop-down"
            options={competencies}
            loading={false}
            startIdx={0}
            onChange={competencyHandler}/>
      </div>
      <div className="form">
        <label>CPD Points: </label>
        <input
          id="cpd-points"
          className="text-box"
          type="number"
          placeholder="1.0"
          step="0.5"
          min="0"
          max="10"
          onChange={cpdPointHandler}/>
      </div>
      <div className="form">
        <label>CPD Provider: </label>
        <input
          id="cpd-provider"
          className="text-box"
          type="text"
          placeholder="CPD Provider"
          onChange={cpdProviderHandler}/>
      </div>
      <Button onClick={createExternalCpdRecord}>Submit</Button>
    </StyledForms>
  )
}
