import DropDownSelect, { IDropDownSelect, OptionsType } from '../ui/drop-down-select';
import { AnnotatedRegistration } from '@lawcpd/learner/shared/data';

interface RegistrationsSelectionProps {
  id: string;
  placeholder?: string;
  registrations: AnnotatedRegistration[];
  showStatus?: boolean;
  showLearner?: Record<string, string>;
  onChange?: (r: OptionsType<string>[] | OptionsType<string>) => void;
}

function status(r: AnnotatedRegistration): string {
  if (r.data.completed) {
    return 'Completed';
  } else if(r.data.started) {
    return 'In Progress';
  } else {
    return 'Not Started';
  }
}

export const RegistrationsSelection = (props: RegistrationsSelectionProps) => {
  const
    { placeholder, id, registrations, showLearner, showStatus, onChange } = props,
    sortedRegistrations = registrations.sort((a,b) => {
      return a.course.data.courseName.localeCompare(b.course.data.courseName, 'en', { sensitivity: 'base' });
    }).map((r) => ({
      value: r.id,
      label: r.course.data.courseName + (showLearner ? ' -- ' + showLearner[r.learnerId] : '') + (showStatus ? ' (' + status(r) + ')' : '')
    }));

  return (
    <DropDownSelect
      id={ id }
      options={sortedRegistrations}
      loading={false}
      placeholder={ placeholder || '-- SELECT REGISTRATION --'}
      isMulti={true}
      onChange={onChange}
    />
  )
}
