import { SearchRefinement, SearchString } from '@lawcpd/learner/shared/data';
import { useEventLogs } from 'support-provider';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router';

import styled from 'styled-components';
import DropDownSelect, { OptionsType } from '../ui/drop-down-select';

const StyledTableOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .controller .disabled-input {
    cursor: wait;
    user-select: none;
  }

  .controller button {
    cursor: pointer;
    margin-right: 5px;
  }

  .text-box {
    min-height: 38px;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
  }

  #filter-controller {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  #filter-controller > * {
    flex: 0 2 auto;
    margin-right: 5px;
  }

  #filter-controller input{
    width: auto;
  }
`;

export const StyledRefinementsAndSelection = styled.div`
  .searches-pills span {
    background-color: #FFFFE6;
    color:black;
  }

  .refinements-pills span {
    background-color: rgb(48, 131, 226, 0.29);
    color:black;
  }

  .selected-pills span {
    background-color: #CCFFCC;
    color: black;
  }
`;

interface EventsSearchProps {
  applyEventChanges: () => void;
  getEventChanges: Record<string, object>;
}

export const EventsSearch = (props: EventsSearchProps) => {
  const
    { limit, loading,
      setSearchTerms, setSearchRefinements,
      getEvents, limitHandler, eventLogs,
    } = useEventLogs(),
    { learnerEmail } = useParams<{ learnerEmail: string }>(),
    { getEventChanges, applyEventChanges } = props,
    [ page, setPage ] = useState(0),
    [ isPrevEnd, setIsPrevEnd ] = useState(true),
    [ gsiToUse, setGSIToUse ] = useState('learnerId'),
    [ gsiSearchTerm, setGSISearchTerm ] = useState('ALLOCATE COURSE');

  const selectLimitHandler = (e: OptionsType<number>) => {
    const val = e.value;

    if(!isNaN(+val)){
      limitHandler(+val);
    }
  }

  const pageBtnHandler = (p: number) => {
    const navPage = page + p;
    // Prev = -1; Next = +1

    getEvents(navPage < 0 ? 0 : navPage);
    setPage(navPage < 0 ? 0 : navPage);
    setIsPrevEnd(navPage <= 0)
  }

  const searchGSIDropDownHandler = (e: OptionsType<string>) => {
    const val = e.value;

    if(val !== 'type'){
      setGSISearchTerm('');
    }
    else{
      const ddlGSISearch = document.getElementsByName('search-input-ddl-gsi')[0] as HTMLInputElement;

      setGSISearchTerm((ddlGSISearch && ddlGSISearch.value )|| 'ALLOCATE COURSE');
    }
    setGSIToUse(val)
  }

  const searchInputDropDownHandler = (e: OptionsType<string>) => {
    const val = e.value;

    setGSISearchTerm(val)
  }

  const searchInputTextBoxHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const val = e.target.value;

    setGSISearchTerm(val);
  }

  const searchTextBoxHandler = (e: KeyboardEvent) => {

    if(e.key === 'Enter'){
      e.preventDefault();
      addSearchInputHandler();
    }
  }

  const addSearchInputHandler = () => {
    const
      userIdSearch: HTMLInputElement =
        document.getElementById('user-id-box') as HTMLInputElement,
      statusOptionDDL: HTMLInputElement =
        document.getElementsByName('search-ddl-status')[0] as HTMLInputElement;

    const
      terms: SearchString[] = [],
      refinements: SearchRefinement[] = [],
      userIdTxt = userIdSearch.value,
      statusOptionTxt = statusOptionDDL.value;

    if(userIdTxt){
      terms.push({
        value: userIdTxt,
        criteria: 'userId'
      });
    }

    if(gsiSearchTerm && gsiToUse){
      terms.push({
        value: gsiSearchTerm,
        criteria: gsiToUse
      });
    }

    if(statusOptionTxt && statusOptionTxt != 'ALL'){
      refinements.push({
        value: statusOptionTxt,
        category: 'statusMessage'
      });
    }

    setSearchTerms(terms);
    setSearchRefinements(refinements);
  }

  const applyNoteChanges = () => {

    try{
      applyEventChanges();
      alert('Changes has been saved')
    }
    catch(e){
      alert('ERROR: '+ e);
    }
    finally{
      addSearchInputHandler()
    }
  }

  // Only once as part of a open new tab with email search
  useEffect(() => {
    if(learnerEmail){
      setSearchTerms([{ criteria: 'learnerId', value: learnerEmail }]);
    }
  }, [])

  return (
      <StyledTableOptions className='spancontainer' id='table-controller'>
        <span id='filter-controller' className='controller'>
          <label htmlFor='user-id-box'>
            Support Staff Email
          </label>
          <input
            type='text'
            name='user-id-box'
            id='user-id-box'
            className={`text-box ${loading ? 'disabled-input' : ''}`}
            disabled={loading}
            onKeyDown={searchTextBoxHandler}
            placeholder='Search'></input>
          <DropDownSelect
            options={[
            { value: 'date', label: 'Date' },
            { value: 'type', label: 'Type' },
            { value: 'learnerId', label: 'Learner Email' }
            ]}
            startIdx={2}
            loading={loading}
            name='search-ddl-gsi'
            id='search-ddl-gsi'
            onChange={searchGSIDropDownHandler}
            isSearchable={false}
          />
          { gsiToUse === 'type' &&
            <DropDownSelect
              options={[
                'ALLOCATE COURSE',
                'DEALLOCATE COURSE',
                'TRANSFER COURSE',
                'PRESIGNED COURSE URL',
                'COURSE SCORM UPLOAD',
                'COURSE SCORM CONFIG'
              ]}
              startIdx={0}
              loading={loading}
              name='search-input-ddl-gsi'
              id='search-input-ddl-gsi'
              onChange={searchInputDropDownHandler}
              isSearchable={false}
            />
          }
          { gsiToUse !== 'type' &&
            <input
              type='text'
              name='gsi-box'
              id='gsi-box'
              className={`text-box ${loading ? 'disabled-input' : ''}`}
              disabled={loading}
              onChange={searchInputTextBoxHandler}
              onKeyDown={searchTextBoxHandler}
              placeholder='Search'
            />
          }
          <label htmlFor='search-ddl-status'>
            Status
          </label>
          <DropDownSelect
            options={['OK', 'FAILED', 'ALL']}
            startIdx={0}
            loading={loading}
            name='search-ddl-status'
            id='search-ddl-status'
            isSearchable={false}
          />
          <button
            className={loading ? 'disabled-input' : ''}
            disabled={loading}
            onClick={addSearchInputHandler}>
              Search
          </button>
          {Object.keys(getEventChanges).length > 0 &&
            <div>
              <button onClick={applyNoteChanges}>Save Notes</button>
            </div>
          }
        </span>

        <span id='page-controller' className='controller'>
          <DropDownSelect
            options={[10, 25, 50, 100, 200]}
            startIdx={0}
            loading={loading}
            name='search-ddl-page'
            id='search-ddl-page'
            onChange={selectLimitHandler}
          />
          <label htmlFor='events-per-page'>per page</label>
          <button
            className={loading ? 'disabled-input' : ''}
            disabled={loading || isPrevEnd}
            onClick={() => pageBtnHandler(-1)}
            >
              Prev
          </button>
          <button
            className={loading ? 'disabled-input' : ''}
            disabled={loading || eventLogs.length < limit}
            onClick={() => pageBtnHandler(1)}
            >
              Next
          </button>
        </span>
      </StyledTableOptions>
  )
}
