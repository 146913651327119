import React, { useState } from 'react';

interface TextToggleProps {
  text: string;
  textRenderCb?: (s: string) => any;
  textChangeCb?: (s: string) => any
}

const TextToggle = (props: TextToggleProps) => {
  const
    { text, textRenderCb, textChangeCb } = props,
    [ textValue, setTextValue ] = useState(text),
    [ editText, setEditText ] = useState(false);

  const editToggleHandler = () => {
    setEditText(true);
  };

  const changeTextHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter'){
      e.preventDefault();
      setTextValue(e.currentTarget.value);
      setEditText(false);

      if(textChangeCb) textChangeCb(e.currentTarget.value);
    }
  }

  const renderCallback = () => {
    if (textRenderCb) { return textRenderCb(textValue); }
    else { return textValue; }
  }

  return(
    <span>
      {editText &&
        <input type='text' placeholder={textValue} onKeyDown={changeTextHandler}/>
      }
      {!editText &&
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-edit"
            width="24" height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            onClick={editToggleHandler}
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
              <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
              <path d="M16 5l3 3"></path>
          </svg>
          <span>
            {renderCallback()}
          </span>
        </>
      }
    </span>
  )
}

export default TextToggle;

