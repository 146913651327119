import { ILearner } from "@lawcpd/learner/shared/data"
import { useEnvironment } from "@lawcpd/learner/shared/provider";
import { ModalBackdrop, ModalPromptTypes } from "@lawcpd/learner/shared/ui";
import Cookies from 'js-cookie';
import { KeyboardEvent, ChangeEvent, useState } from "react";
import styled from 'styled-components';
import { useFirebaseAuth } from "support-provider";

const StyledMasqueradeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;

  p {
    text-align: center;
  }

  .error-text {
    background-color: #fef2f2;
    color: #c10007;
    font-weight: 500;
    width: max-content;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid #fcd9da
  }

  .warning-text {
    background-color: #fefce8;
    color: #a16f30;
    font-weight: 500;
    width: max-content;
    max-width: 50%;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid #f5e5ba
  }

  .result-text {
    background-color: #eff6ff;
    color: #406aeb;
    font-weight: 500;
    width: max-content;
    max-width: 50%;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid #d9e4fd
  }

  .masquerade-email {
    padding: 1rem 2rem;
    width: 50%;
    margin: 0 auto;
  }
`

export const MasqueradeLearner = (props: {
  learner: ILearner
  onCancel: () => void;
}) => {
  const
    { onCancel, learner } = props,
    { user } = useFirebaseAuth(),
    { cookiesDomain, mycpd: { api } } = useEnvironment(),
    [ masqueradeEmail, setMasqueradeEmail ] = useState(""),
    [ isLoading, setIsLoading ] = useState(false),
    [ changes, setChanges ] = useState(""),
    [ error, setError ] = useState("");

  function validateEmail(email: string){
    const regExp = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    return regExp.test(email);
  }

  function keyDownHandler(e: KeyboardEvent<HTMLInputElement>){
    if(e.key === 'Enter'){
      masqueradeBtnHandler();
    }
  }

  function textChangeHandler(e: ChangeEvent<HTMLInputElement>){
    e.preventDefault();
    setMasqueradeEmail(e.target.value);
  }


  function masqueradeBtnHandler() {
    if(validateEmail(masqueradeEmail)){
      setError("");
      setChanges("");
      const dryRun = confirm("Do you want to run this in dry run mode (test mode)?");

      user.getIdToken()
      .then((token) => {
        document.cookie = 'fbt=' + token + '; domain=' + cookiesDomain + '; path=/; samesite=lax; secure';
        const init = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json"
          },
          body: JSON.stringify({ masqueradeEmail, dryRun })
        };
        return fetch(`${api}/api/learner/${learner.id}/masquerade`, init);
      })
      .then( _ => _.json())
      .then((result) => {
        const
          resTargetAccount: ILearner = result[learner.id],
          resMasqueradeAccount: ILearner = result[masqueradeEmail];

        if(!resTargetAccount){
          throw new Error("Result of target learner account is invalid");
        }
        else if(!resMasqueradeAccount){
          throw new Error("Result of masquerade learner account is invalid");
        }

        // This is to confirm the changes from the client side
        setChanges(`
          ${masqueradeEmail} now can masquerade into ${resMasqueradeAccount.data.email};
          ${learner.data.email} should still be pointing to ${resTargetAccount.data.email}.
          ${dryRun ? "If you're satisfied with this, run this again with dry run disabled" : ""}
        `)
      })
      .catch((e) => {
        setIsLoading(false);

        if(e instanceof TypeError){
          setError(`Network error: ${e.message}`)
        }
        else if(e instanceof Error){
          setError(`Error: ${e.message}`);
        }
        else{
          setError(`An error occurred while setting up the masquerade between the two accounts: ${e}`)
        }
      })
    }
    else{
      setError("Please provide a valid email address.");
    }
  }

  return (
    <ModalBackdrop
      backdropId='backdrop-root'
      overlayId='overlay-root'
      type={"confirm" as ModalPromptTypes}
      title='Masquerade Learner'
      onConfirm={masqueradeBtnHandler}
      onCancel={onCancel}
      onClose={onCancel}
      confirmText='Masquerade'
      cancelText='Cancel'
      isLoading={isLoading}
      >
    <StyledMasqueradeWrapper>
      <p>Provide the masquerading account's email address to start masquerading the selected user <strong>{learner.data.email}</strong></p>
      <p className="warning-text">
        Be warned: Masquerading an account that don't have any account in Auth0 may overwrite the learner details with the masquerading account;
        this was the case of masquerading an account in staging that was synced down from production but does not have a corresponding Auth0 account.
      </p>
      { changes &&
        <p className="result-text">{changes}</p>
      }
      { error &&
        <p className="error-text">{error}</p>
      }
      <input
        className="masquerade-email"
        type="email"
        placeholder="Masquerade Email"
        onKeyDown={keyDownHandler}
        onChange={textChangeHandler}
        disabled={isLoading}
      />
    </StyledMasqueradeWrapper>
    </ModalBackdrop>
  )
}
