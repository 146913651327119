import styled from 'styled-components';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { useFirebaseAuth } from 'support-provider';
import { NavLink } from 'react-router-dom';

/** ASSETS */
import { theme } from '@lawcpd/learner/shared/ui'
import MagentoIcon from '../assets/icons/magento.png';
import ScormIcon from '../assets/icons/scormcloud.png';
import RiseIcon from '../assets/icons/rise360.png';
import HelpIcon from '../assets/icons/help.png';
import LogoutIcon from '../assets/icons/logout.png';

const StyledNavigation = styled.ul`
display: flex;
flex-direction: row;
justify-content: space-between;
list-style-type: none;
margin: 0;
padding: 0.8rem 0.8rem 0;

.help, .logout {
  display: none;
}

a {
  color: ${theme.font};
  text-decoration: none;

  &.active {
    color: ${theme.orange};
  }
}

img, svg {
  height: 3.2rem;
  margin-right: 0.8rem;
  vertical-align: middle;
  width: 3.2rem;
}

li {
  margin-bottom: 0.8rem;

  &.dashboard {
    span:before {
      content: "";
    }
  }
}

@media only screen and ${theme.largeDevice} {
  padding-left: ${theme.gutter};
  padding-right: ${theme.gutter};

  .help {
    display: block;
  }
}

@media only screen and ${theme.extraLargeDevice} {
  display: block;
  padding: 0;

  .help {
    flex-grow: 1;
  }

  .logout {
    bottom: 1.6rem;
    display: block;
    position: fixed;
  }

  li {
    margin: 2rem 0;

    &.dashboard {
      margin-top: 0;
    }

    &.logout {
      margin-bottom: 0;
    }

  }
}

@media only screen and ${theme.notLargeDevice} {
  font-size: 1.3rem;
  justify-content: center;
  margin: 0;

  img, svg {
    display: block;
    margin: 0 auto;
  }

  li {
    margin: 0 0.8rem;
    text-align: center;
  }

}
`;

export const Navigation = (props) =>{
  const
    { help, login: { logout }, support: { login }} = useEnvironment(),
    { loading, isAuthenticated, logOut: logOutFromFirebase } = useFirebaseAuth();

  return (
  <StyledNavigation>
    {!loading && isAuthenticated &&
      <>
        <li className="dashboard"><NavLink end to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
            <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
            <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
          </svg>
          <span>Support Dashboard</span></NavLink></li>
        <li className="nav-reports"><NavLink end to="/report">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
            <path d="M12 17v-6"></path>
            <path d="M9.5 14.5l2.5 2.5l2.5 -2.5"></path>
          </svg>
          <span>Download Reports</span></NavLink>
        </li>
        <li className="event-logs"><NavLink end to="/event-log">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M8 9l3 3l-3 3"></path>
            <line x1="13" y1="15" x2="16" y2="15"></line>
            <rect x="3" y="4" width="18" height="16" rx="2"></rect>
          </svg>
          <span>Event Logs</span></NavLink>
        </li>
        <li className="course-upload"><NavLink end to="/course-upload">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M14 20h-8a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12v5"></path>
            <path d="M11 16h-5a2 2 0 0 0 -2 2"></path>
            <path d="M15 16l3 -3l3 3"></path>
            <path d="M18 13v9"></path>
          </svg>
          <span>Upload Courses</span></NavLink>
        </li>
        <li className="business-analytics"><NavLink end to="/business-analytics">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M9 12v-4"></path>
            <path d="M15 12v-2"></path>
            <path d="M12 12v-1"></path>
            <path d="M3 4h18"></path>
            <path d="M4 4v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-10"></path>
            <path d="M12 16v4"></path>
            <path d="M9 20h6"></path>
          </svg>
          <span>Business Analytics</span></NavLink></li>
        <li className="sync-course"><NavLink end to="/sync-course">
          <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
            <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
          </svg>
          <span>Sync Course</span></NavLink></li>
        <li className="magento-admin"><a href='https://lawcpd.com.au/admin/admim'><img src={MagentoIcon} /><span>Magento Admin</span></a></li>
        <li className="scorm-admin"><a href={'https://app.cloud.scorm.com/sc/guest/SignInForm'} target="_blank"><img src={ScormIcon} />SCORM Admin</a></li>
        <li className="rise-admin"><a href={'https://articulate.com/360/trial?existing=true&lp=existing'} target="_blank"><img src={RiseIcon} />Rise360 Admin</a></li>
        <li className="external-cpd"><NavLink end to="/external-cpd"><img src="../assets/icons/external-cpd.svg" /><span>External CPD Records</span></NavLink></li>
        <li className="help"><a href={help} target="_blank"><img src={HelpIcon} />Help</a></li>
        <li className="logout" onClick={logOutFromFirebase}><a><img src={LogoutIcon} />Logout</a></li>
      </>
    }
  </StyledNavigation>
  )
}

export default Navigation;
