import { KeyboardEvent, useState } from 'react'

import { useLearners } from '@lawcpd/learner/shared/provider';
import styled from 'styled-components';
import DropDownSelect, { OptionsType } from '../ui/drop-down-select';
import SpanPill from '../ui/span-pill';
import { SearchString } from '@lawcpd/learner/shared/data';

const StyledTableOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .controller .disabled-input {
    cursor: wait;
    user-select: none;
  }

  .controller button {
    cursor: pointer;
    margin-right: 5px;
  }

  .text-box {
    min-height: 38px;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    padding-left: 5px;
  }

  #filter-controller {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  #filter-controller div{
    flex: 0 2 auto;
    margin-top: 0.5em;
  }

  #filter-controller > div > * {
    margin-right: 5px;
    width: 12em;
  }

`;

export const StyledRefinementsAndSelection = styled.div`
  .searches-pills span {
    background-color: #FFFFE6;
    color:black;
  }

  .refinements-pills span {
    background-color: rgb(48, 131, 226, 0.29);
    color:black;
  }
`;


const LearnerSearch = (props) => {
  const
    {
      limit,
      searchTerms,
      setSearchTerms,
      getLearners,
      limitHandler,
      learners,
      loading,
    } = useLearners(),
    [ page, setPage ] = useState(0),
    [ isPrevEnd, setIsPrevEnd ] = useState(true);

  // ===================== SEARCH HANDLERS ===================== //
  //#region

  const selectLimitHandler = (e: OptionsType<number>) => {
    const val = e.value;

    if(!isNaN(+val)){
      limitHandler(+val);
    }
  }

  const pageBtnHandler = (p: number) => {
    const navPage = page + p;
    // Prev = -1; Next = +1

    getLearners(navPage < 0 ? 0 : navPage);
    setPage(navPage < 0 ? 0 : navPage);
    setIsPrevEnd(navPage <= 0)
  }

  const searchTextBoxHandler = (e: KeyboardEvent) => {

    if(e.key === 'Enter'){
      e.preventDefault();
      addSearchInputHandler(e.currentTarget.id);
    }
  }

  const addSearchInputHandler = (id: string) => {
    let criteria;
    switch(id){
      case 'search-fn-box': criteria = 'First Name'; break;
      case 'search-ln-box': criteria = 'Last Name'; break;
      case 'search-eml-box': criteria = 'Email'; break;
      case 'search-eml-dmn-box': criteria = 'Email Domain'; break;
      default: return;
    }

    const searchBoxTxt: HTMLInputElement = document.getElementById(id) as HTMLInputElement;

    if(!searchBoxTxt) return

    const
      searchTxt = searchBoxTxt.value,
      searchCriteria = criteria,
      searchInput: SearchString = {
        value: searchTxt,
        criteria: searchCriteria
      },
      isExistsInInputs = searchTerms.some((d) => {
        return d.value === searchTxt && d.criteria === searchCriteria;
      });

    clearHandler(searchBoxTxt as HTMLInputElement);
    if(!searchTxt || !searchCriteria || isExistsInInputs) return;

    // Remove any of the same criteria, only the latest goes through
    const remaining = searchTerms.filter((d) => {
      return d.criteria.toLocaleLowerCase() !== searchCriteria.toLocaleLowerCase();
    });

    setSearchTerms([...remaining, searchInput]);
  }

  const removeSearchInputHandler = (idx: number) => {
    if(!searchTerms[idx]) return;
    const
      beforeElements = searchTerms.slice(0,idx),
      afterElements = searchTerms.slice(idx+1),
      elements = [...beforeElements, ...afterElements];
      setSearchTerms(elements)
  }

  const searchBtnHandler = () => {
    // Iteratively check all input if they were filled out
    const inputs = ['search-fn-box', 'search-ln-box', 'search-eml-box', 'search-eml-dmn-box'];

    for(const i of inputs) addSearchInputHandler(i);
  }

  const clearHandler = (e: HTMLInputElement) => {
    e.value = '';
  }
  //#endregion

  return (
    <div>
      <StyledRefinementsAndSelection>
        <div className='searches-pills'>
          {searchTerms.length > 0 && <label>Search terms:</label>}
          {searchTerms.map((d, i) => { return (
            <SpanPill
              key={i}
              idx={i}
              displayTxt={`${d.criteria}:${d.value}`}
              loading={loading}
              removeHandler={removeSearchInputHandler}
              /> )
          })}
        </div>
      </StyledRefinementsAndSelection>
      <StyledTableOptions className='spancontainer' id='table-controller'>
        <span id='filter-controller' className='controller'>
            <div>
              <label>First Name:</label>
              <input
                type='text'
                name='search-fn-box'
                id='search-fn-box'
                className={`text-box ${loading ? 'disabled-input' : ''}`}
                disabled={loading}
                onKeyDown={searchTextBoxHandler}
                placeholder='Search First Name'></input>
            </div>
            <div>
              <label>Last Name:</label>
              <input
                type='text'
                name='search-ln-box'
                id='search-ln-box'
                className={`text-box ${loading ? 'disabled-input' : ''}`}
                disabled={loading}
                onKeyDown={searchTextBoxHandler}
                placeholder='Search last name'></input>
            </div>
            <div>
              <label>Email:</label>
              <input
                type='text'
                name='search-eml-box'
                id='search-eml-box'
                className={`text-box ${loading ? 'disabled-input' : ''}`}
                disabled={loading}
                onKeyDown={searchTextBoxHandler}
                placeholder='Search email'></input>

            </div>
            <div>
              <label>Email Domain:</label>
              <input
                type='text'
                name='search-eml-dmn-box'
                id='search-eml-dmn-box'
                className={`text-box ${loading ? 'disabled-input' : ''}`}
                disabled={loading}
                onKeyDown={searchTextBoxHandler}
                placeholder='Search email domain'></input>
            </div>
            <div>
              <button
                className={loading ? 'disabled-input' : ''}
                disabled={loading || (searchTerms.length === 0)}
                onClick={searchBtnHandler}>
                  Search
              </button>
            </div>
        </span>

        <span id='page-controller' className='controller'>
          <DropDownSelect
            options={[10, 25, 50, 100, 200]}
            startIdx={0}
            loading={loading}
            name='search-ddl-page'
            id='search-ddl-page'
            onChange={selectLimitHandler}
          />
          <label htmlFor='learners-per-page'>per page</label>
          <button
            className={loading ? 'disabled-input' : ''}
            disabled={loading || isPrevEnd}
            onClick={() => pageBtnHandler(-1)}
            >
              Prev
          </button>
          <button
            className={loading ? 'disabled-input' : ''}
            disabled={loading || learners.length < limit}
            onClick={() => pageBtnHandler(1)}
            >
              Next
          </button>
        </span>
      </StyledTableOptions>
    </div>
  )
}

export default LearnerSearch;
