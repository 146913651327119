import { ChangeEvent, KeyboardEvent, useState } from 'react';
import styled from 'styled-components';
import SpanPill from '../ui/span-pill'
import DropDownSelect, { OptionsType } from '../ui/drop-down-select';
import { AnnotatedAllocation, ECompetencyType, getCpdYear, ILearner } from '@lawcpd/learner/shared/data';
import {
  completed, notStarted, inProgress,
  filterCompletedInCpdYears, filterCompletedInCpdYear, filterInCpdYears,
  getCpdPoints, getCompetencies, notCompleted
} from '@lawcpd/learner/shared/filter';


const StyledSpan = styled.span`
  margin-top:1em;
  display: inline-block;

  svg {
    fill: none;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 0.5em;
  }

  input {
    margin-left: 0.5em;
    width: 3em;
  }

  span {
    margin-bottom: 0px;
    vertical-align: middle;
  }
`

interface LearnerCourseInformationProps {
  registrations: AnnotatedAllocation[]
}

const getCompletedRegistrations = (registrations: AnnotatedAllocation[]) => {
  return registrations.filter((r) => r.data.completed);
}

export const getLatestCompletedRegistration = (registrations: AnnotatedAllocation[]) => {
  return getCompletedRegistrations(registrations)
  .sort((a,b) => {
    return b.data.completed.getTime() - a.data.completed.getTime();
  })[0];
}

export const LearnerCourseInformation = (props: LearnerCourseInformationProps ) => {
  const
    cpdYear = getCpdYear(new Date),
    { registrations} = props,
    [ year, setYear ] = useState(cpdYear),
    [ isAllYears, setIsAllYears ] = useState(false),
    [ showYearInput, setShowYearInput ] = useState(false),
    [ yearSelections, setYearSelections ] = useState<number[]>([cpdYear]);

  // Course completed, incomplete, in progress
  const
    notCompletedInCpdYears = isAllYears ? notCompleted : filterInCpdYears(yearSelections),
    completedInCpdYears = isAllYears ? completed : filterCompletedInCpdYears(yearSelections),
    registrationsCompletedInCPDYears = registrations.filter(completedInCpdYears),
    incompleteRegistrationsInCPDYears = registrations.filter(notCompletedInCpdYears),
    inProgressRegistrationsInCPDYears = incompleteRegistrationsInCPDYears.filter(inProgress),
    notStartedRegistrationsInCPDYears = incompleteRegistrationsInCPDYears.filter(notStarted);

  // ===== Handlers ===== //
  //#region
  const dropDownYearFilterHandler = (e: OptionsType<string>) => {

    const val = e.value;

    setIsAllYears(val === 'All CPD years');
  }

  const yearInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const
      currentInput = e.target.value,
      numberOnly = new RegExp(/^[0-9\b]+$/);

    if((numberOnly.test(currentInput) || currentInput === '') && currentInput.length <= 4){
      setYear(+currentInput);
    }
  }

  const yearInputAddHandler = (e: KeyboardEvent) => {
    if(e.key === 'Enter'){
      e.preventDefault();
      setShowYearInput(false);
      // Skip if already in selection
      if(yearSelections.includes(year)) return;

      const years = [...yearSelections, year];

      setYearSelections(years.sort((a,b) => { return a - b;} ));
    }
  }

  const removeYearsSelected = (idx: number) => {
    const year = yearSelections[idx];
    // Cannot reduce selection to 0
    if(!year || yearSelections.length === 1) return;
    const
      beforeElements = yearSelections.slice(0,idx),
      afterElements = yearSelections.slice(idx+1);

    setYearSelections([...beforeElements, ...afterElements]);
  }

  const showYearSelection = () => {
    return(
      <StyledSpan>
        {yearSelections.map((y, i) =>
          <SpanPill
            key={y}
            idx={i}
            displayTxt={y}
            removeHandler={removeYearsSelected}
          />
        )}
        { showYearInput &&
          <input
            autoFocus={true}
            type="text"
            value={yearSelections.includes(year) || !year ? '' : year}
            placeholder="Year"
            onChange={yearInputHandler}
            onKeyDown={yearInputAddHandler}/>
        }
        { !showYearInput &&
          <svg xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          strokeWidth="2" stroke="currentColor"
          strokeLinecap="round" strokeLinejoin="round"
          onClick={() => setShowYearInput(true)}
          >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
          <path d="M9 12l6 0"></path>
          <path d="M12 9l0 6"></path>
        </svg>
        }

      </StyledSpan>
    )
  }

  const formatLearnerInfo = (registrations: AnnotatedAllocation[], type?: string) => {
    if(registrations.length === 0) return `No course ${type ? type : 'available'}`;

    return (
      <ol>
        {registrations.map((r, i) => {
          return(
            <li key={i}><i>{r.course.data.courseName}</i>
              <ul>
                {type === 'started' || type === 'completed' &&
                  <li><b>{`Date ${type}: `}</b>{r.data[type].toDateString()}</li>
                }
                <li><b>CPD Points: </b>{r.course.data.cpdPoints}</li>
              </ul>
            </li>)
        })}
      </ol>
    );
  }
  //#endregion

  return (
    <>
      { registrations.length > 0 &&
        <ul>
          <li><b>Courses yet to be started: </b>
          {formatLearnerInfo(notStartedRegistrationsInCPDYears)}
          </li>
          <li><b>Courses in progress: </b>
          {formatLearnerInfo(inProgressRegistrationsInCPDYears, 'started')}
          </li>
          <li><b>Courses completed
            (<DropDownSelect
              options={['All CPD years', 'Specific CPD year(s)']}
              startIdx={1}
              loading={false}
              onChange={dropDownYearFilterHandler}
            />): </b>
          { !isAllYears &&
            <div><i>Displaying courses completed in: </i>{showYearSelection()}</div>
          }
          {formatLearnerInfo(registrationsCompletedInCPDYears, 'completed')}
          </li>
        </ul>
      }
      { registrations.length === 0 &&
        <h4>No registrations assigned for the learner...</h4>
      }
    </>
  )
}

export const LearnerCourseCompetencyInformation = (props: LearnerCourseInformationProps) => {
  const
    cpdYear = getCpdYear(new Date),
    { registrations } = props;

  const getCompetencyForCPDYear = () => {
    const
      latestCompleted = getLatestCompletedRegistration(registrations),
      completedInCpdYear = filterCompletedInCpdYear(cpdYear),
      registrationsCompletedInCPDYear = registrations.filter(completedInCpdYear),
      cpdPointsInCpdYear = getCpdPoints(registrationsCompletedInCPDYear),
      completedCompetenciesInCpdYear = getCompetencies( // Returns null if no competency
        registrationsCompletedInCPDYear
      ),
      hasCompetencies = (
        completedCompetenciesInCpdYear.has(ECompetencyType.Ethics) &&
        completedCompetenciesInCpdYear.has(ECompetencyType.PracticeManagement) &&
        completedCompetenciesInCpdYear.has(ECompetencyType.ProfessionalSkills) &&
        completedCompetenciesInCpdYear.has(ECompetencyType.SubstantiveLaw)
      );

    return (
      <>
      { latestCompleted &&
        <li><b>Last course completed: </b>
          <ul>
            <li><b>Date: </b>{(new Date(latestCompleted.data.completed)).toDateString()}</li>
            <li><b>Course: </b>{latestCompleted.course.data.courseName}</li>
          </ul>
        </li>
      }
      <li><b>Total CPD points ({cpdYear}): </b>{cpdPointsInCpdYear}</li>
      { !hasCompetencies &&
        <li><b>No competencies gained within the year.</b></li>
      }
      { hasCompetencies &&
        <li><b>Competencies gained within the year:</b>
          <ul>
            { completedCompetenciesInCpdYear.has(ECompetencyType.Ethics) &&
              <li><b>Ethics</b></li>
            }
            { completedCompetenciesInCpdYear.has(ECompetencyType.PracticeManagement) &&
              <li><b>Practice Management</b></li>
            }
            { completedCompetenciesInCpdYear.has(ECompetencyType.ProfessionalSkills) &&
              <li><b>Professional Skills</b></li>
            }
            { completedCompetenciesInCpdYear.has(ECompetencyType.SubstantiveLaw) &&
              <li><b>Substantive Law</b></li>
            }
          </ul>
        </li>
      }
      </>
    )
  }


  return(
    <>
      { registrations.length > 0 &&
        getCompetencyForCPDYear()
      }
      { registrations.length === 0 &&
        <li>No courses assigned for the learner.</li>
      }
    </>
  );
}

export default LearnerCourseInformation
