import { useEffect, useState } from 'react';
import { useCourses } from '@lawcpd/learner/shared/provider';
import DropDownSelect, { OptionsType } from '../ui/drop-down-select';
import { ICourse } from '@lawcpd/learner/shared/data';

interface CoursesSelectionProps  {
  id: string;
  placeholder?: string;
  isPurchasable?: boolean;
  includeCoursePacks?: boolean;
  selected?: string[],
  showMagentoProductId?: boolean;
  onChange: (r: OptionsType<string>[] | OptionsType<string>) => void
}

function removeCoursePacks(c: ICourse): boolean { return c.data.type === 'virtual'; }
function removeNotPurchasable(c: ICourse): boolean { return c.data.isPurchasable; }

export const CoursesSelection = (props: CoursesSelectionProps) => {
  const
    { id, isPurchasable, includeCoursePacks, onChange, selected, showMagentoProductId, ...rest} = props,
    { courses, error, loading } = useCourses(),
    [ sortedCourses, setSortedCourses ] = useState<OptionsType<string>[]>([]);

  function filterCourse (course: ICourse): boolean {
    const
      filterCoursePacks = includeCoursePacks ? true : removeCoursePacks(course),
      filterNotPurchasable = isPurchasable ? removeNotPurchasable(course) : true;

    return filterCoursePacks && filterNotPurchasable;
  }

  useEffect(() => {
    if (!loading && !error) {
      const
        courseSrc = Object.values(courses),
        filteredCourseSrc = courseSrc.filter(filterCourse);
      setSortedCourses(filteredCourseSrc.sort((a,b) => {
          return a.data.courseName.localeCompare(b.data.courseName, 'en', { sensitivity: 'base' });
        }).map((c) => ({
          value: c.id,
          label: c.data.courseName + (showMagentoProductId && c.ids.magento ? ' (' + c.ids.magento + ')' : '')
        })
      ));
    }
  }, [loading]);

  return (
    <DropDownSelect
      id={ id }
      options={sortedCourses}
      loading={loading}
      placeholder={'-- SELECT COURSE --'}
      isMulti={true}
      onChange={ onChange }
      { ...( selected && { value: sortedCourses.filter((c) => selected.includes(c.value)) })}
      {...rest}
    />
  )
}
