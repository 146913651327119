import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { useFirebaseAuth } from 'support-provider';
import { Backdrop, BackdropSpinner, ModalBackdrop, ModalPromptTypes } from '@lawcpd/learner/shared/ui';

import { FormEvent, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import ProgressBar from './ui/progress-bar';

const StyledCourseForm = styled.div`
  .form-row{
    display: flex;
    justify: flex-end
    padding: .5em;
    margin-top: 10px;
  }

  .form-row input {
    width: 10%;
    flex: 8 25vw;
  }

  .form-row label{
    flex: 1;


  }

  button {
    padding: 5px;
    margin-left: 15px;
  }
`;

const StyledProgressBar = styled.div`
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  padding: 2%;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0,0,0,0.26);
  border-radius: 10px;
  z-index: 100;
  width:400px;
  height:150px;
`;

export const CourseUpload = (props) => {
  const
    { user } = useFirebaseAuth(),
    { cookiesDomain, mycpd: { api }} = useEnvironment(),
    [ isLoading, setIsLoading ] = useState(false),
    [ isUploading, setIsUploading ] = useState(false),
    [ isShowModal, setIsShowModal ] = useState(false),
    [ isNewCourse, setIsNewCourse ] = useState(false),
    [ uploadProgress, setUploadProgress ] = useState(0),
    [ courseInfo, setCourseInfo ] = useState({version: 1, regCount:0});

  const deployCoursesToChoices = () => {
    const defaults = [
      ['All', 'WHEN_NEWER_PACKAGE_VERSION_EXISTS'],
      ['Passed', 'WHEN_EXISTING_REG_IS_SATISFIED_AND_NEWER_PACKAGE_VERSION_EXISTS'],
      ['Failed', 'WHEN_EXISTING_REG_IS_FAILED_AND_NEWER_PACKAGE_VERSION_EXISTS'],
      ['Completed', 'WHEN_EXISTING_REG_IS_COMPLETE_AND_NEWER_PACKAGE_VERSION_EXISTS'],
      ['Incomplete', 'WHEN_EXISTING_REG_IS_INCOMPLETE_AND_NEWER_PACKAGE_VERSION_EXISTS'],
      ['Never', 'NEVER']
    ];

    return (
      <select
        id='deploy-criteria'
        name='deploy-criteria'
        defaultValue={defaults[defaults.length-1][1]}
        disabled={isLoading}
        >
          {defaults.map((d, i) => {
            return <option value={d[1]} key={i}>{d[0]}</option>
          })}
      </select>
    )
  }

  const closeModalHandler = () => {
    setIsShowModal(false);
  }

  const getCourseInfo = (id: number, token: string) =>{
    return fetch(`${api}/api/course/scorm-1-${id}?type=scorm`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then(res =>{
      if(res.ok) {
        return res.json();
      }
      else if(res.status === 404) {
        return null;
      }
      else throw new Error('Error getting the course info.');
    })
    .then(data => {
      if(!data) return data;
      return {...data[0]}
    });
  }

  const getPresignedUrl = (filename: string, token: string): Promise<{url: string, hashedFile: string}> => {
    const rb = {
      data: { filename: filename },
      group: 'course',
      name: 'presigned'
    };

    return fetch(`${api}/api/course/presigned`,
      {
        method: 'POST',
        body: JSON.stringify(rb),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      }
    )
    .then(res =>{
      if(res.ok) {
        return res.json();
      }
      else throw new Error('An error occured in procuring a presigned url.');
    })
    .then(data => {
      if(!data.url || !data.hashedFile) throw new Error('No presigned url or hashed filename returned');
      return data;
    });
  }

  const getJobStatus = (jobId: string, token: string, retries:number = 3): Promise<boolean> => {
    if(retries <= 0) {
      throw new Error('Retries exhausted retrieving job status');
    }

    const waitDelay = (ms: number) => new Promise(res => setTimeout(res, ms));

    return waitDelay(10000)
    .then(()=>{
      return fetch(`${api}/api/course/upload/status/${jobId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
    })
    .then(async (res) => {
      const resultText = await res.text();
      if(!res.ok){
        await waitDelay(10000);
        return getJobStatus(jobId, token, retries-1);
      }

      if(resultText === 'COMPLETE') {
        return true;
      }
      else if(resultText === 'RUNNING') {
        // Probably a better way to do this than to wait indefinitely
        // for job status to finish
        await waitDelay(10000);
        return getJobStatus(jobId, token, retries);
      }
      else { // anything else means job failed
        return false;
      }
    });
  }

  const setCourseConfig = (id: number, deploy: HTMLSelectElement, token: string): Promise<boolean> =>{

    if(!id && !deploy.value) throw new Error('No ID or deploy value provided')

    const rb = {
      data: {
        id: `scorm-1-${id}`,
        propagate_changes: deploy.value,
        player_launch: 'FRAMESET',
        sco_launch: 'FRAMESET',
        rollup_at_suspend: true,
        rollup_at_sco_unload: true
      },
      group: 'course',
      name: 'config'
    };

    return fetch(`${api}/api/course/config`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(rb),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    )
    .then(res =>{
      if(res.ok || res.status === 404) {
        return true;
      }
      else throw new Error('An error occured setting the course config');
    });
  }

  const uploadCourseToS3 = (file: File, presignedUrl: string, token: string): Promise<boolean> => {
    return fetch(presignedUrl,
      {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'application/zip'
        }
      }
    )
    .then(res =>{
      if(res.ok) {
        return true;
      }
      else throw new Error('An error occured in uploading the course to the presigned url');
    });
  }

  const uploadCourseToScorm = (fileName: string, magentoId: number, token: string): Promise<string> => {
    if(!magentoId || !fileName) throw new Error('No Scorm ID provided');
    const rb = {
      data:{
        magentoId: magentoId,
        filename: fileName
      },
      group: 'course',
      name: 'upload'
    };

    return fetch(`${api}/api/course/upload`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(rb),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    )
    .then(res =>{
      if(res.ok) {
        return res.text();
      }
      else throw new Error('No Job ID processed');
    });
  }

  const uploadPromptHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const
      magento: HTMLInputElement = document.getElementById('magento-id') as HTMLInputElement,
      course: HTMLInputElement = document.getElementById('course-file') as HTMLInputElement;

    if(!magento.value || course.files.length === 0 || isNaN(+magento.value)) return;

    setIsLoading(true);

    const magentoId = +magento.value;

    user.getIdToken()
    .then((token) => {
      document.cookie = 'fbt=' + token + '; domain=' + cookiesDomain + '; path=/; samesite=lax; secure';
      // Retrieves the scorm information given the magento ID
      return getCourseInfo(magentoId, token).then(results =>{
        setIsLoading(false);
        setIsShowModal(true);
        if(!results){
          setIsNewCourse(true);
          setCourseInfo({version: 1, regCount:0})
          return;
        }

        const
          courseData = results[`scorm-1-${magento}`],
          scormData = courseData && courseData.scormData;
        if(scormData && scormData.registrationCount && scormData.version){
          const { registrationCount, version } = scormData;
          setIsNewCourse(false);
          setCourseInfo({ regCount: registrationCount, version: version });
        }
        else{
          setIsNewCourse(true);
          setCourseInfo({version: 1, regCount:0})
        }
      })
    })
    .catch(e =>{
      console.error(e.message);
    })
  }

  const uploadHandler = () => {
    const
      magento: HTMLInputElement = document.getElementById('magento-id') as HTMLInputElement,
      course: HTMLInputElement = document.getElementById('course-file') as HTMLInputElement,
      deployTo: HTMLSelectElement = document.getElementById('deploy-criteria') as HTMLSelectElement;

    setIsShowModal(false);
    setIsUploading(true);
    user.getIdToken()
    .then((token) => {
      const
        magentoId = +magento.value,
        file = course.files[0];

      setUploadProgress(20);
      let hashedFileName = file.name;
      return getPresignedUrl(file.name, token)
      .then((presigned) => {
        hashedFileName = presigned.hashedFile;

        // Create a new file from hashedfile name
        const
          blob = file.slice(0, file.size, 'application/zip'),
          toSend = new File([blob], `${hashedFileName}.zip`, {type: 'application/zip'});

        setUploadProgress(40);
        return uploadCourseToS3(toSend, presigned.url, token);
      })
      .then((isUploadOk) => {
        // If new upload then set config, else set config then upload
        if(isNewCourse){
          return uploadCourseToScorm(hashedFileName, magentoId, token)
          .then((jobId) => {

            setUploadProgress(60);
            return getJobStatus(jobId, token);
          })
          .then((isUploadFinished) => {

            setUploadProgress(80);
            return setCourseConfig(magentoId, deployTo, token);
          })
          .then((isSetConfigOk) => {
            setUploadProgress(100);
            return true;
          })
        }
        else if(!isNewCourse){
          // We need to set the configuration earlier to determine what group of learners do we apply this
          return setCourseConfig(magentoId, deployTo, token)
          .then((isSetConfigOk) =>{
            setUploadProgress(60);
            return uploadCourseToScorm(hashedFileName, magentoId, token);
          })
          .then((jobId) => {
            setUploadProgress(80);
            return getJobStatus(jobId, token);
          })
          .then((isUploadFinished) => {
            setUploadProgress(100);
            return true;
          })
        }
      })
    })
    .then((result) => {
      alert('Course upload successful');
    })
    .catch(e => {
      alert(`An error occured while uploading: ${e.message}`);
    })
    .finally(() => {
      setIsUploading(false);
      setUploadProgress(0);
    });
  }


  return (
    <StyledCourseForm>
      {isUploading && !isShowModal && ReactDOM.createPortal(
        <Backdrop>
          <StyledProgressBar>
            <h1>Upload in progress</h1>
            <ProgressBar progress={uploadProgress}/>
          </StyledProgressBar>
        </Backdrop>,
        document.getElementById('backdrop-root')
      )}
      {isLoading && !isShowModal &&
        <BackdropSpinner backdropId='backdrop-root' />
      }
      {isShowModal &&
        <ModalBackdrop
          backdropId='backdrop-root'
          overlayId='overlay-root'
          type={('confirm' as ModalPromptTypes)}
          onConfirm={uploadHandler}
          onClose={closeModalHandler}
          onCancel={closeModalHandler}
          confirmText='OK'
          cancelText='Cancel'
        >
          {!isNewCourse &&
            <h3>Updating the version to {courseInfo.version + 1} from {courseInfo.version} will affect {courseInfo.regCount} registration(s).</h3>
          }
          {isNewCourse &&
            <h3>You are creating a new course.</h3>
          }
          <div className='form-row'>
              <label>Apply this course to: </label>
              {deployCoursesToChoices()}
          </div>
        </ModalBackdrop>
      }
      <form onSubmit={uploadPromptHandler}>
        <div className='form-row'>
          <label>Magento ID</label>
          <input placeholder='Magento ID:' name='magento-id' id='magento-id'autoComplete='off'/>
        </div>
        <div className='form-row'>
          <label htmlFor='course-file'>Course file: </label>
          <input type='file' name='course-file' id='course-file' accept='.zip'/>
        </div>
        <div className='form-row'>
          <button type='submit' >Upload</button>
          <button>Clear</button>
        </div>
      </form>
    </StyledCourseForm>
  )
}

export default CourseUpload;
