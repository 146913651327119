import { IOrder } from '@lawcpd/learner/shared/data';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { useFirebaseAuth } from 'support-provider';
import { useEffect, useState } from 'react';

interface LearnerOrderInformationProps {
  learnerId: string
}

export const LearnerOrderInformation = (props: LearnerOrderInformationProps) => {
  const
    { cookiesDomain, domain, mycpd: { api } } = useEnvironment(),
    { user } = useFirebaseAuth(),
    { learnerId } = props,
    [ learnersOrderInfo, setLearnersOrderInfo ] = useState<IOrder[]>([]),
    [ isLearnersOrderInfoError, setIsLearnersOrderInfoError ] = useState(false),
    [ isLearnersOrderInfoLoading, setIsLearnersOrderInfoLoading ] = useState(true);

  const formatOrderInfo = (order: IOrder) => {
    const orderDetailPage = `https://${domain}/admin/sales/order/view/order_id/${order.orderId}`;

    return(
      <li key={order.number}>
        {order.orderId &&
          <a href={orderDetailPage}><b>Order# {order.number}</b></a>
        }
        {!order.orderId &&
          <b>Order# {order.number}</b>
        }
        <ul>
          <li><b>Date:</b>{` ${order.created.toDateString()}`}</li>
          <li><b>Amount:</b>{` $${order.amount.toFixed(2)}`}</li>
          {order.items && order.items.length &&
            <li>
              <b>Ordered Items:</b>
              <ol>
                {order.items.map((i) => <li key={i}>{i}</li>)}
              </ol>
            </li>
          }
        </ul>
      </li>
    )
  }

  const getMagentoInfo = () => {
    const endPoint = `${api}/api/learner/${learnerId}/ecommerce`;

    user.getIdToken()
    .then((token) => {
      const init = { headers: { Authorization: `Bearer ${token}` } };
      document.cookie = 'fbt=' + token + '; domain=' + cookiesDomain + '; path=/; samesite=lax; secure';

      return fetch(endPoint, init);
    })
    .then(_ => _.json())
    .then((res: Record<string, any>) => {
      const
        magentoInfo = res[learnerId],
        orders = magentoInfo.ordersRecent;

      if ("error" in orders) {
        setIsLearnersOrderInfoError(true);
      } else {
        setLearnersOrderInfo(orders.map(order => ({
          amount: order.grand_total,
          created: new Date(order.created_at.replace(/ /g,"T")),
          number: order.increment_id,
          orderId: order.entity_id,
          items: order.items.map((i) => i.name)
        })));
      }
      setIsLearnersOrderInfoLoading(false);
    })
    .catch(e => {
      console.error(e);
      setIsLearnersOrderInfoError(true);
      setIsLearnersOrderInfoLoading(false);
    });
  }

  useEffect(() => {
    if(user){
      getMagentoInfo();
    }
  },[user])


  return (
    <>
      { isLearnersOrderInfoError &&
        <h4>An error occured while retrieving last orders.</h4>
      }
      { isLearnersOrderInfoLoading &&
        <h4>Retrieving last orders...</h4>
      }
      { !isLearnersOrderInfoError &&
        !isLearnersOrderInfoLoading &&
        <>
        { learnersOrderInfo.length > 0 &&
          <ul>
            {learnersOrderInfo.slice(0,3).map(formatOrderInfo)}
          </ul>
        }
        { learnersOrderInfo.length === 0 &&
          <h4>No recent orders...</h4>
        }
        </>
      }
    </>
  )
}

export default LearnerOrderInformation;
