import React, { useState } from 'react';
import styled from 'styled-components';
import LearnerInfo from './learner-info';

import { ILearner } from '@lawcpd/learner/shared/data';
import { DropDownSelect } from './ui/drop-down-select';
import { ModalBackdrop, ModalPromptTypes } from '@lawcpd/learner/shared/ui';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { useFirebaseAuth } from 'support-provider';

const StyledLearners = styled.div`
  .learner-merge-entry svg{
    width: 25px;
    height: 25px;
    margin-right: 10px;
    cursor: pointer;
  }

  .learner-merge-entry label{
    font-weight: bold;
  }

  .learner-merge-entry-alt{
    font-style: italic;
    font-size: 0.5em;
  }
`

const StyledLearnersList = styled.ul`
  list-style-type:none;
  font-size: xx-large;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap:wrap;

  li{
    flex-basis:50%;
    flex-grow:1;
  }
`;

const StyledDropDown = styled.div`
  display: flex;
  justify-content: center;

  div{
    vertical-align: center;
  }

  select{
    padding: 1% 3%;
    text-align: center;
    font-size: medium;
    margin-left:10px;
  }
`

interface IMergeLearnersProps {
  children?: React.ReactElement;
  learners: ILearner[];
  onCancel: () => void;
}

const LearnersToBeMerged = (l: ILearner) => {
  const [ showLearnerInfo, setShowLearnerInfo ] = useState(false);

  return (
    <StyledLearners>
      <div className={'learner-merge-entry'}>
        <span>
          <svg
            onClick={() => setShowLearnerInfo(true)}
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-user-circle"
            width="24" height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <circle cx="12" cy="12" r="9"></circle>
            <circle cx="12" cy="10" r="3"></circle>
            <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"></path>
          </svg>
        </span>
        <label>{`${l.data.givenName} ${l.data.familyName}`}</label>
      </div>
      <div className={'learner-merge-entry-alt'}>{`(${l.data.email})`}</div>
      {showLearnerInfo &&
        <LearnerInfo
          learner={l}
          onCloseLearnerInfo={() => setShowLearnerInfo(false)}
        />
      }
    </StyledLearners>
  )
}

export const MergeLearners = ({ children, learners, onCancel}: IMergeLearnersProps) => {
  const
    { user } = useFirebaseAuth(),
    { mycpd: { api } } = useEnvironment();

  const getLearnerDetailsFromId = (id: string) => {
    const learner = learners.find((l) => { return l.id === id; });

    // Returned sliced learner ID if for some reason not found
    if(!learner) return id.slice(0,6);

    const { givenName, familyName, email } = learner.data;
    return `${givenName} ${familyName} (${email})`;
  }
  const annotateErrorsandWarnings = (error) => {
    let errStr = '', warnStr = '';
    if(!error) return '';

    if(error.errors){
      const errors = error.errors.map((e) => {


        return `${e.type}${e.idType ? ` (${e.idType})` : ''}: ${getLearnerDetailsFromId(e.id)}`
      });

      const errTemp = '\nThere are problems with merging'
      + ' the parent account and the sub account(s):';


      errStr = `${errTemp} \n- ${errors.join('\n- ')}\n`;
    }

    if(error.warnings){
      const warnings = error.warnings.map((w) => w.type);

      const warnTemp = '\nThere are some discrepancies between'
      + ' the parent account and the sub account(s):';

      warnStr = `${warnTemp} \n- ${warnings.join('\n- ')}`;
    }

    return (errStr + warnStr);
  }

  const mergeBtnHandler = () => {
    const
      parentLearnerElem = document.getElementById('drop-down-learner-merge') as HTMLSelectElement,
      parentLearnerIdx = +parentLearnerElem.value;

    if(isNaN(parentLearnerIdx) && parentLearnerIdx < 0) return;

    const
      parentAccount = learners[parentLearnerIdx],
      parentId = parentAccount.id,
      subAccounts = learners.filter((l) => {
        return l.id !== parentId;
      }),
      subAccountsIds = subAccounts.map((l) => l.id),
      mergeEndpoint = `${api}/api/learner/${parentId}/merge`;

    user.getIdToken()
    .then(async (token) => {
      const
      rb = subAccountsIds,
      init = {
        body: JSON.stringify(rb),
        mode: 'cors' as RequestMode,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };

      const
        dryRun = await fetch(`${mergeEndpoint}?dryRun=1`, init),
        dryRunRes = await dryRun.json(),
        dryRunErrWarn = annotateErrorsandWarnings(dryRunRes[0]) || 'No errors or warnings found.';

      if(dryRun.status >= 400) {
        throw new Error(dryRunErrWarn);
      }

      if(confirm(dryRunErrWarn + '\nWould you like to proceed finalizing the merge?')){
        const
          final = await fetch(`${mergeEndpoint}?dryRun=0`, init),
          finalRes = await final.json(),
          finalErrWarn = annotateErrorsandWarnings(finalRes[0]);

        if(final.status >= 400) {
          throw new Error(finalErrWarn);
        }

        alert('Merge successful!');
      }
    })
    .catch((e) => {
      alert(`Merge failed \n${e.message}`);
    })
  }

  return (
    <ModalBackdrop
      backdropId='backdrop-root'
      overlayId='overlay-root'
      type={('confirm' as ModalPromptTypes)}
      title='Merge Learners'
      onConfirm={mergeBtnHandler}
      onCancel={onCancel}
      onClose={onCancel}
      confirmText='Merge'
      cancelText='Cancel'
      >
      <StyledLearnersList>
        {learners.length > 0 &&
          learners.map((l, i) => {
            return (
              <li key={i}>
                {LearnersToBeMerged(l)}
              </li>
            );
          })
        }
      </StyledLearnersList>
      <StyledDropDown>
        <label><b>Primary Learner Account:</b></label>
        <div>
          <DropDownSelect
            options={
              learners.map((l, i) => {
                const { givenName, familyName, email } = l.data;
                return {
                  value: i,
                  label: `${givenName} ${familyName} (${email})`
                };
              })
            }
            startIdx={0}
            loading={false} // All learners are already loaded
            id='drop-down-learner-merge'
          />
        </div>
      </StyledDropDown>
    </ModalBackdrop>
  )
}
