import SpanPill from '../ui/span-pill';
import { RegistrationsSelection } from './drop-down-registrations';
import { AnnotatedRegistration, ILearner } from '@lawcpd/learner/shared/data';
import { StyledLearnerFormWrapper } from './assign-courses';
import { StyledRefinementsAndSelection } from './learner-search';
import { OptionsType } from '../ui/drop-down-select';

export type RegistrationFromLearner = {
  registration: string,
  courseId: string,
  courseName: string,
  newLearner: string,
  oldLearner: string
};

interface AssignFromLearnersComponentSelectionProps {
  targetLearners: ILearner[];
  sourceLearners: ILearner[];
  assignmentType: 'allocate' | 'deallocate';
  directAssignments: Record<string, string[]>;
  registrations: AnnotatedRegistration[];
  assignHandler: (r: RegistrationFromLearner[]) => void;
  removeSourceLearnerHandler: (i: number) => void;
  toggleAddSourceLearnerWindow: (b: boolean) => void;
};

interface LearnerComponentProps extends AssignFromLearnersComponentSelectionProps{
  learner: ILearner;
};

const LearnerComponent = (props: LearnerComponentProps) => {
  const
    {
      learner, targetLearners, sourceLearners, registrations,
      assignmentType, directAssignments, assignHandler,
    } = props,
    learnersToUse = assignmentType === 'deallocate' ? targetLearners : sourceLearners,
    learnersToUseIds = learnersToUse.map((l) => l.id),
    learnerNamesMap = learnersToUse.reduce((names, l) => {
      const { givenName, familyName, email } = l.data || {};

      if(!givenName || !familyName || !email) {
        return names;
      }
      names[l.id] = `${givenName} ${familyName} (${email})`;
      return names;
    }, {}),
    directAssignmentsIds = Object.values(directAssignments).reduce((p, c) => {
      return [...p, ...c]
    },[]),
    dropDownRegistrations = registrations.filter((r) => {
      return (
        !directAssignmentsIds.includes(r.id)
        && learnersToUseIds.includes(r.learnerId)
      );
    });

  // =================== HANDLERS =================== //
  //#region

  const addCourseSelected = (selected: OptionsType<string>[]) => {
    const
      selectedIds = selected.map((s) => s.value),
      annotatedSelection = dropDownRegistrations
        .filter((r) => selectedIds.includes(r.id))
        .map((r) => ({
          registration: r.id,
          courseId: r.courseId,
          courseName: r.course.data.courseName,
          oldLearner: r.learnerId,
          newLearner: learner.id
        }
      )),
      coursesNotAlreadyDirectlyAssigned = annotatedSelection.filter((s) => {
        const
          toCompare = assignmentType === 'deallocate' ? s.registration : s.courseId,
          compareWith = assignmentType === 'deallocate'
            ? directAssignmentsIds
            : directAssignments[learner.id];

        // Does not exist as it's absolutely empty
        if(!compareWith) return true;
        // Has at least one allocation or deallocation already, check if it exists
        else return !compareWith.includes(toCompare);

      });

    if(!coursesNotAlreadyDirectlyAssigned.length) return;
    assignHandler(coursesNotAlreadyDirectlyAssigned);
  }

  //#endregion

  return (
    <div className={'learner-form-row'} key={learner.id}>
      <label>{`${learner.data.givenName} (${learner.data.email})`}</label>
      <RegistrationsSelection
        id = { `course-from-selection-${learner.id}` }
        registrations = { dropDownRegistrations }
        showLearner = { learnerNamesMap }
        showStatus = { true }
        onChange = { addCourseSelected }
      />
    </div>
  )
}

export const AssignFromLearnersComponentSelection = (props: AssignFromLearnersComponentSelectionProps) => {
  const
    {
      targetLearners, sourceLearners, removeSourceLearnerHandler,
      assignmentType, toggleAddSourceLearnerWindow
    } = props,
    btnTxt = assignmentType === 'deallocate'
      ? 'Add learners to transfer courses to'
      : 'Add learners to transfer courses from',
    learnersFrom = assignmentType === 'deallocate' ? sourceLearners : targetLearners;

  return (
    <StyledRefinementsAndSelection>
      <div className='selected-pills'>
        {sourceLearners.length > 0 && <label>Selected learners:</label>}
        {sourceLearners.map((d, i) => { return (
          <SpanPill
            key={i}
            idx={i}
            displayTxt={`${d.data.givenName} ${d.data.familyName} (${d.data.email})`}
            loading={false}
            removeHandler={() => removeSourceLearnerHandler(i)}
          /> )
        })}
        <br/>
        <button
          onClick={() => toggleAddSourceLearnerWindow(true)}
          >
          {btnTxt}
        </button>
        <StyledLearnerFormWrapper>
          {sourceLearners.length > 0 && learnersFrom.map((l) => {
            return (
              <LearnerComponent
                key = {l.id}
                learner = {l}
                {...props}
                />
            );
          })}
        </StyledLearnerFormWrapper>
      </div>
    </StyledRefinementsAndSelection>
  )
}
