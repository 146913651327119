// todo: Is this the best place for this?
import { ICourse } from './learner-shared-data';

export function authorsByCourses(courses: ICourse[]) {
  return [...new Set(courses.filter(c => c.data.authorName).map(c => c.data.authorName.trim()))].sort();
}

export function coursesByAuthor(authorName: string, courses: ICourse[]) {
  return courses.filter(c => c.data.authorName && c.data.authorName.trim() === authorName).map(c => c.id);
}
