import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DropDownSelect, OptionsType } from './ui/drop-down-select';
import { useCourses, useEnvironment } from '@lawcpd/learner/shared/provider';
import { useFirebaseAuth } from 'support-provider';
import { authorsByCourses, coursesByAuthor, getCpdYear } from '@lawcpd/learner/shared/data';
import { GeneratedReports } from './data/generated-reports';
import { Alert } from '@lawcpd/learner/shared/ui';
import { CoursesSelection } from './data/drop-down-courses';
import { useFeatureFlags } from '@lawcpd/feature-flags/provider';

const StyledReports = styled.div`
  margin-bottom: 5rem;

  fieldset {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    padding: 1rem;
  }

  fieldset label{
    margin-right: 5rem;
  }

  i{
    margin-left: 1em;
    color: red;
    font-size: 0.7em;
  }

  a{
    padding: 1em 1em;
    color: white;
    background-color: #f37021;
    border-radius: 1rem;
    cursor: pointer;
    margin-right:1em;
  }

  #cpdms-year-dropdown {
    padding: 0 1rem;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
  }

  #cpdms-report-year {
    border: 2px solid #8f8f9d;
    border-radius: 5px;
    margin-bottom: 1.5rem;
  }
`;

const StyledDateTime = styled.div`
	display: inline-block;
	align-items: center;
	background-color: #fff;
	border: 2px solid #8f8f9d;
  border-radius: 5px;
  margin-right: 1rem;

	&:focus-within {
		border-color: teal;
	}

	input {
		font: inherit;
		color: inherit;
		appearance: none;
		outline: none;
		border: 0;
		background-color: transparent;

		&[type=date] {
			border-right-width: 0;
		}

		&[type=time] {
			border-left-width: 0;
		}
	}

	span {
		height: 1rem;
		margin-right: .25rem;
		margin-left: .25rem;
		border-right: 1px solid #ddd;
	}
`

const StyledRoyalty = styled.div`
  display: flex;
  align-items: center;

	select {
    flex-grow: 2;
    margin: 0 1rem 0 0.5rem;

    &.courses {
      flex-grow: 4;
    }
	}
`

export const Reports = (props) => {
  const
    today = new Date(),
    cpdYear = getCpdYear(today),
    cpdYears = [],
    [ isAllTime, setIsAllTime ] = useState(false),
    [ author, setAuthor ] = useState(''),
    [ year, setYear ] = useState(''),
    [ from, setFrom ] = useState(0),
    [ to, setTo ] = useState(0),
    [ reportType, setReportType ] = useState('lawcpd'),
    [ royaltyReport, setRoyaltyReport ] = useState(''),
    { cookiesDomain, mycpd: { api } } = useEnvironment(),
    { user } = useFirebaseAuth(),
    { courses, error, loading } = useCourses(),
    [ coursesList, setCoursesList ] = useState([]),
    [ selectedCourses, setSelectedCourses ] = useState([]),
    [ authors, setAuthors ] = useState([]),
    [ showGeneratedReport, setShowGeneratedReport ] = useState(false),
    [ showReportAlert, setShowReportAlert ] = useState(false),
    [ reportGenerationRequests, setReportGenerationRequests ] = useState<string[]>([]),
    { featureFlags: { USE_UPLOADED_REPORTS_10765 } }= useFeatureFlags(),
    isCspl = reportType === 'cspl',
    isRoyalty = reportType === 'royalty',
    maxDate = (isRoyalty ? new Date(cpdYear + '-03-31T23:59:59') : today).toLocaleDateString('en-CA'), // yyyy-mm-dd
    reportsApi = `${api}/api/registration/report`;

  for (let y = cpdYear; y > 2009; y--) { cpdYears.push(y); }

  useEffect(() => {
    if (!loading && !error) {
      const list = Object.values(courses);
      setAuthors(authorsByCourses(list));
      setCoursesList(list);
    }
  }, [loading]);

  const authorHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const newAuthor = event.target.value;
    if (newAuthor) { // Select appropriate courses
      setSelectedCourses(coursesByAuthor(newAuthor, coursesList));
    } else { // unselect all courses
      setSelectedCourses([]);
    }
    setAuthor(newAuthor);
  };

  const courseHandler = (e: OptionsType<string>[]) => {
    const
      newCourses = e.map(e => e.value),
      authors = authorsByCourses(newCourses.map(id => courses[id])),
      author = authors.length === 1 && coursesByAuthor(authors[0], coursesList).length === newCourses.length ? authors[0] : '';

    setSelectedCourses(newCourses);
    setAuthor(author);
  };

  const downloadReportHandler = (e: MouseEvent) => {
    e.preventDefault();

    if(!isCspl && !isRoyalty && (isNaN(+year) || year.length < 4)) return;

    const params: Record<string, any> = {};

    if(!isRoyalty && year) params.cpdYear = year;
    if(from) params.from = from;
    if(to) params.to = to;
    if(isRoyalty && selectedCourses.length) params.courseId = selectedCourses.join();

    const searchParams = (new URLSearchParams(params)).toString();
    let reportEndPoint = searchParams ? `${reportsApi}/${reportType}?${searchParams}` : `${reportsApi}/${reportType}`;

    // Set cookie before accessing the reports to keep up to date token
    user.getIdToken()
    .then((token) => {
      document.cookie = 'fbt=' + token + '; domain=' + cookiesDomain + '; path=/; samesite=lax; secure';
      if(!USE_UPLOADED_REPORTS_10765){
        window.top.location.href = reportEndPoint;
      }
      else{
        return fetch(reportEndPoint, {
          method: "GET",
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        })
        .then((res) => res.json())
        .then((data) => {
          if(!data.jobId){
            throw new Error("Missing job id from the report generation response");
          }
          setShowReportAlert(true);
          setTimeout(() => setShowReportAlert(false), 1000);

          setReportGenerationRequests([...reportGenerationRequests, data.jobId])
        });
      }
    })
    .catch((e) => {
      console.error(e);
      alert(`An error occured while generating the report: ${e.message}`);
    });
  };

  const yearKeyHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const
      currentInput = e.target.value,
      numberOnly = new RegExp(/^[0-9\b]+$/);

    if((numberOnly.test(currentInput) || currentInput === '') && currentInput.length <= 4){
      setYear(currentInput);
    }
  };

  const fromHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const
      date = document.getElementById('date-from') as HTMLInputElement,
      time = document.getElementById('time-from') as HTMLInputElement,
      inputDate = new Date(date.value);

    // Cannot exceed current date
    if(date.value && (inputDate > today)){
      date.value = maxDate;
    }
    if(date.value){
      const
        appendedDateTime = date.value + (time.value ? ` ${time.value}` : ''),
        dateTimeVal = new Date(appendedDateTime);
      setFrom(dateTimeVal.getTime());
    }
    setRoyaltyReport('');
  };

  const toHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const
      date = document.getElementById('date-to') as HTMLInputElement,
      time = document.getElementById('time-to') as HTMLInputElement,
      inputDate = new Date(date.value);

    // Cannot exceed current date
    if(date.value && (inputDate > today)){
      date.value = maxDate;
    }
    if(date.value){
      const
        appendedDateTime = date.value + (time.value ? ` ${time.value}` : ''),
        dateTimeVal = new Date(appendedDateTime);
      setTo(dateTimeVal.getTime());
    }
    setRoyaltyReport('');
  };

  const reportTypeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const currentInput = e.target.value;
    setReportType(currentInput);
  };

  const royaltyReportHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    const
      value = e.target.value,
      [from, to] = value !== '' ? value.split('-').map(s => parseInt(s, 10)) : [0, 0],
      dFrom = from ? new Date(from) : null,
      dTo = to ? new Date(to) : null,
      dateFrom = document.getElementById('date-from') as HTMLInputElement,
      timeFrom = document.getElementById('time-from') as HTMLInputElement,
      dateTo = document.getElementById('date-to') as HTMLInputElement,
      timeTo = document.getElementById('time-to') as HTMLInputElement;

    dateFrom.value = from ? dFrom.toLocaleDateString('en-CA') : ''; // yyyy-mm-dd
    timeFrom.value = '';
    dateTo.value = to ? dTo.toLocaleDateString('en-CA') : ''; // yyyy-mm-dd
    timeTo.value = '';
    setRoyaltyReport(value);
    setFrom(from ? dFrom.getTime() - dFrom.getTimezoneOffset() * 60000 : 0);
    setTo(to ? dTo.getTime() - dTo.getTimezoneOffset() * 60000 : 0);
  };

  const allTimeHandler = (e: OptionsType<string>) => {
    const value = e.value;

    if(value === 'All time'){
      setYear('');
      setIsAllTime(true);
    }
    else {
      setIsAllTime(false);
    }
  }

  return (
    <StyledReports>
      {showReportAlert && USE_UPLOADED_REPORTS_10765 &&
        <Alert overlayId='overlay-root'>
          <p>Your report is being generated.</p>
        </Alert>
      }
      { showGeneratedReport && USE_UPLOADED_REPORTS_10765 &&
        <GeneratedReports onCancel={() => setShowGeneratedReport(false)}/>
      }
      { !isRoyalty && <>
        { isCspl && <>
          <DropDownSelect
            id='cpdms-year-dropdown'
            options={["CPD year", "All time"]}
            loading={loading}
            startIdx={0}
            onChange={allTimeHandler}
          />
          {!isAllTime &&
            <input id='cpdms-report-year' value={year} type='text' placeholder='Year of Report' onChange={yearKeyHandler}/>
          }
        </>}
        {!isCspl && <>
          <label>CPD year: </label>
          <input id='cpdms-report-year' value={year} type='text' placeholder='Year of Report' onChange={yearKeyHandler}/>
          <i>* year of report is required</i>
        </> }
      </> }
      { isRoyalty && <StyledRoyalty>
        <label>Report:</label>
        <select onChange={royaltyReportHandler} value={ royaltyReport }>
          <option value=''>None</option>
          { cpdYears.flatMap(y => {
            const
              april = new Date((y - 1) + '-04-01T00:00:00'), // Date-only forms are interpreted as a UTC time and date-time forms are interpreted as local time.
              july = new Date((y - 1) + '-07-01T00:00:00'),
              october = new Date((y - 1) + '-10-01T00:00:00'),
              december = new Date((y - 1) + '-12-01T00:00:00'),
              january = new Date(y + '-01-01T00:00:00'),
              nextApril = new Date(y + '-04-01T00:00:00'),
              ret = [];
            ret.push([[april.getTime(), nextApril.getTime() - 1].join('-'), 'CPD Year ' + y]);
            ret.push([[october.getTime(), nextApril.getTime() - 1].join('-'), 'April ' + y]);
            ret.push([[april.getTime(), october.getTime() - 1].join('-'), 'October ' + (y - 1)]);
            ret.push([[january.getTime(), nextApril.getTime() - 1].join('-'), 'Video Arts April ' + y]);
            ret.push([[december.getTime(), january.getTime() - 1].join('-'), 'Video Arts January ' + y]);
            ret.push([[october.getTime(), december.getTime() - 1].join('-'), 'Video Arts December ' + (y - 1)]);
            ret.push([[july.getTime(), october.getTime() - 1].join('-'), 'Video Arts October ' + (y - 1)]);
            ret.push([[april.getTime(), july.getTime() - 1].join('-'), 'Video Arts July ' + (y - 1)]);
            return ret;
          }).map(r => <option key={ r[0] } value={ r[0] }>{ r[1] }</option>) }
        </select>
        <label>Author:</label>
        <select onChange={authorHandler} value={author}>
          <option value=''>None</option>
          { authors.map(a => <option key={ a }>{ a }</option>) }
        </select>
        <label>Courses:</label>
        <CoursesSelection
          id={ 'course-selection-royalty' }
          onChange={ courseHandler }
          selected={ selectedCourses }
          showMagentoProductId= { true }
        />
      </StyledRoyalty>}
      <br/>
      <label>From: </label>
      <StyledDateTime id="cpdms-from">
        <input type="date" id="date-from" onChange={fromHandler} max={maxDate}/>
        <span></span>
        <input type="time" id="time-from" onChange={fromHandler} max={maxDate}/>
      </StyledDateTime>
      <label>To: </label>
      <StyledDateTime id="cpdms-to">
        <input type="date" id="date-to" onChange={toHandler} max={maxDate}/>
        <span></span>
        <input type="time" id="time-to" onChange={toHandler} max={maxDate}/>
      </StyledDateTime>
      <fieldset>
        <legend>Select the type of report to download</legend>
        <div>
          <input
            type='radio'
            id='rd-btn-report-lawcpd'
            name='report-type'
            value='lawcpd'
            defaultChecked={true}
            onChange={reportTypeHandler}
            />
          <label htmlFor='rd-btn-report-lawcpd'>LawCPD</label>
          <input
            type='radio'
            id='rd-btn-report-lpbwa'
            name='report-type'
            value='lpbwa'
            onChange={reportTypeHandler}
            />
          <label htmlFor='rd-btn-report-lpbwa'>LPBWA</label>
          <input
            type='radio'
            id='rd-btn-report-cspl'
            name='report-type'
            value='cspl'
            onChange={reportTypeHandler}
            />
          <label htmlFor='rd-btn-report-cspl'>Course Status by Learner</label>
          <input
            type='radio'
            id='rd-btn-report-royalty'
            name='report-type'
            value='royalty'
            onChange={reportTypeHandler}
            />
          <label htmlFor='rd-btn-report-royalty'>Royalty</label>
        </div>
      </fieldset>
      <a id='download-report-btn' onClick={downloadReportHandler}>Download Report</a>
      { USE_UPLOADED_REPORTS_10765 &&
        <a onClick={() => setShowGeneratedReport(true)}>Show pregenerated reports</a>
      }
      <br/>
      {reportGenerationRequests && reportGenerationRequests.length !== 0 && USE_UPLOADED_REPORTS_10765 &&
        <label>Reports generated during the current session:
          <ul>
            {reportGenerationRequests.map((r, i) => <li key={i}>{r}</li>)}
          </ul>
        </label>
      }
    </StyledReports>
  )
}
