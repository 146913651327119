import { PrivateRouteAdmin } from '@lawcpd/learner/shared/ui';
import { useFirebaseAuth } from 'support-provider';
import styled from 'styled-components';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Dashboard } from './dashboard';
import { CourseUpload } from './course-upload';
import { CourseSync } from './course-sync';
import Support from './support';
import { EventLogs } from './event-logs';
import { Reports } from './reports';
import { ExternalCpdPage } from './external-cpd';


const StyledApp = styled.div`
`;

const NoMatch = () => {
  const location = useLocation();

  return (
    <Navigate to={{
      pathname: '/'
    }}/>
  )
}

const App = () => {
  const
    { loading } = useFirebaseAuth();

  const
    appRoutes = <Routes>
      <Route path="/" element={
        <PrivateRouteAdmin>
          <Dashboard />
        </PrivateRouteAdmin>
      } />
      <Route path="report" element={
        <PrivateRouteAdmin>
          <Reports />
        </PrivateRouteAdmin>
      } />
      <Route path="course-upload" element={
        <PrivateRouteAdmin>
          <CourseUpload />
        </PrivateRouteAdmin>
      } />
      <Route path="event-log" element={
        <PrivateRouteAdmin>
          <EventLogs />
        </PrivateRouteAdmin>
      } />
      <Route path="event-log/:learnerEmail" element={
        <PrivateRouteAdmin>
          <EventLogs />
        </PrivateRouteAdmin>
      } />
      <Route path="external-cpd" element={
        <PrivateRouteAdmin>
          <ExternalCpdPage />
        </PrivateRouteAdmin>
      } />
      <Route path="sync-course" element={
        <PrivateRouteAdmin>
          <CourseSync />
        </PrivateRouteAdmin>
      } />
      <Route path="*" element={NoMatch()}/>
    </Routes>;

  return (
    <StyledApp>
      <Routes>
        <Route path="login" />
        { !loading &&
          <Route path="*" element={<Support>{appRoutes}</Support>} />
        }
      </Routes>
    </StyledApp>
  );
};


export default App;
