import { useEffect, useState } from 'react';
import { LearnerOrderInformation } from './data/learner-info-order';
import { LearnerAccountInformation } from './data/learner-info-account';
import { ModalBackdrop, ModalPromptTypes } from '@lawcpd/learner/shared/ui';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { useFirebaseAuth } from 'support-provider';
import { AnnotatedAllocation, allocationFromRegistration, ILearner } from '@lawcpd/learner/shared/data';
import { LearnerCourseInformation, LearnerCourseCompetencyInformation } from './data/learner-info-course';

interface LearnerInfoProps {
  learner: ILearner;
  onCloseLearnerInfo?: () => void;
}

const LearnerInfo = (props: LearnerInfoProps ) => {
  const
    { cookiesDomain, mycpd: { api } } = useEnvironment(),
    { user } = useFirebaseAuth(),
    { learner, onCloseLearnerInfo } = props,
    { id, metadata, data: learnerInfo } = learner,
    [ learnersRegistrations, setLearnersRegistration ] = useState<AnnotatedAllocation[]>([]),
    [ isLearnersRegistrationsError, setIsLearnersRegistrationsError ] = useState(false),
    [ isLearnersRegistrationsLoading, setIsLearnersRegistrationsLoading ] = useState(true);

    const getRegistrations = () => {
      const endPoint = `${api}/api/learner/${id}/registration`;

      user.getIdToken()
      .then((token) => {
        const init = { headers: { Authorization: `Bearer ${token}` } };
        document.cookie = 'fbt=' + token + '; domain=' + cookiesDomain + '; path=/; samesite=lax; secure';

        return fetch(endPoint, init);
      })
      .then(_ => _.json())
      .then(registrations => {
        setLearnersRegistration(registrations[0].map(allocationFromRegistration));
        setIsLearnersRegistrationsLoading(false);
      })
      .catch(e => {
        console.error(e);
        setIsLearnersRegistrationsError(true);
        setIsLearnersRegistrationsLoading(false);
      });
    }

    useEffect(() => {
      if(user){
        getRegistrations();
      }
    },[user])

  return (
    <ModalBackdrop
      backdropId='backdrop-root'
      overlayId='overlay-root'
      type={('prompt' as ModalPromptTypes)}
      title='Learner Info'
      onConfirm={onCloseLearnerInfo}
      onClose={onCloseLearnerInfo}
      confirmText='OK'
      {...props}
      >
        <h2>{`${learnerInfo.givenName} ${learnerInfo.familyName}`}</h2>
        <div>
          <h3>Personal information:</h3>
          <hr/>
          <ul>
            <li><b>First Name:</b> {learnerInfo.givenName}</li>
            <li><b>Last Name:</b> {learnerInfo.familyName}</li>
            <li><b>Email:</b> {learnerInfo.email}</li>
            <li><b>Practioner ID:</b> {learnerInfo.practitionerId || 'N/A'}</li>
          </ul>
          <h3>Last Order information:</h3>
          <hr/>
          <LearnerOrderInformation learnerId={id}/>
          <h3>User information:</h3>
          <hr/>
          <ul>
            <li><b>Last Login: </b>
            {
              metadata.login
              ? (new Date(metadata.login).toDateString())
              : 'N/A'
            }
            </li>
            <li>
              <b>Account created: </b>{(new Date(metadata.created)).toDateString()}
            </li>
            <LearnerAccountInformation learner={learner}/>
            { isLearnersRegistrationsError &&
              <li>An error occured while retrieving courses.</li>
            }
            { isLearnersRegistrationsLoading &&
              <li>Retrieving courses...</li>
            }
            { !isLearnersRegistrationsError && !isLearnersRegistrationsLoading &&
              <LearnerCourseCompetencyInformation registrations={learnersRegistrations}/>
            }
          </ul>
          <h3>Learner information:</h3>
          <hr/>
          { isLearnersRegistrationsError &&
            <h4>An error occured while retrieving registrations.</h4>
          }
          { isLearnersRegistrationsLoading &&
            <h4>Retrieving registrations...</h4>
          }
          { !isLearnersRegistrationsError && !isLearnersRegistrationsLoading &&
            <LearnerCourseInformation registrations={learnersRegistrations}/>
          }
        </div>
    </ModalBackdrop>
  )
}

export default LearnerInfo;
