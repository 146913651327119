import styled from 'styled-components';

const StyledSpan = styled.span`
  display: inline-block;
  padding-left: 5px;
  margin-left: 10px;
  margin-bottom: 10px;
  border: solid 1px #DDDDDD;

  button{
    padding: 3px 3px;
    cursor: pointer;
    margin-left: 5px;
    border: solid 0px black;
    background-color: white;
    font-size: 1.8rem;
    color: #ADADAD
  }
`;

interface SpanPillProps {
  idx: number;
  displayTxt: string | number;
  loading?: boolean;
  removeHandler: (i: number) => any;
};

export const SpanPill = (props: SpanPillProps) => {
  const { idx, displayTxt, loading, removeHandler, ...rest } = props;

  if(idx === undefined || idx < 0 || !displayTxt){
    return;
  }

  return (
    <StyledSpan {...rest}>
      {displayTxt}
      <button
        onClick={() => removeHandler(idx)}
        className={loading ? 'disabled-input' : ''}
        disabled={loading}
        >
        x
      </button>
    </StyledSpan>
  )
}

export default SpanPill;
