import { useEnvironment } from "@lawcpd/learner/shared/provider";
import { useState } from "react";
import { useFirebaseAuth } from "support-provider";
import { CoursesSelection } from "./data/drop-down-courses"
import { OptionsType } from "./ui/drop-down-select";

export const CourseSync = (props) => {
  const
    [selectedCourses, setSelectedCourses] = useState<OptionsType<string>[]>([]),
    { cookiesDomain, mycpd: { api }} = useEnvironment(),
    { user } = useFirebaseAuth();

  const selectedCoursesHandler = (selected: OptionsType<string>[]) => {
    setSelectedCourses([...selected]);
  }

  const syncHandler = async () => {
    if(!selectedCourses.length) return;

    const token = await user.getIdToken();
    document.cookie = 'fbt=' + token + '; domain=' + cookiesDomain + '; path=/; samesite=lax; secure';

    const syncs = selectedCourses.map((course) => fetch(`${api}/api/course/${course.value}/sync`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }));

    Promise.allSettled(syncs).then((results) => {
      const failedSyncs = [];
      for(let i = 0; i < results.length; i++){
        const result = results[i];

        if(result.status === 'rejected') failedSyncs.push(selectedCourses[i].label);
      }

      if(failedSyncs.length){
        alert(`Failed the following sync of courses:\n- ${failedSyncs.join("\n- ")}`);
      }
      else{
        alert("Successfully synced all courses.")
      }
    });
  }

  return (
    <div>
      <label>Select courses to sync</label>
      <CoursesSelection
        id="course-sync"
        includeCoursePacks={true}
        showMagentoProductId={true}
        onChange={ selectedCoursesHandler }
      />
      <button onClick={syncHandler}>Sync</button>
    </div>
  )
}

export default CourseSync;
