import styled from 'styled-components';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { useFirebaseAuth } from 'support-provider';
import { theme } from '@lawcpd/learner/shared/ui'

const StyledWelcome = styled.h1`
  flex-grow: 1;
  font-size: 2rem;
  margin: 0;

  a {
    color: ${theme.orange};
    text-decoration: none;
  }

  @media only screen and ${theme.notLargeDevice} {
    background-color: white;
    box-sizing: border-box;
    height: 4.6rem;
    padding: 0.8rem 0.8rem;
    text-align: center;
    vertical-align: center;

    span:first-child {
      display: none;
    }

    a:before {
      color: ${theme.font};
      content: "Hi ";
    }
  }
`;

const StyledLearner = styled.div`
  flex-basis: 48rem;
  flex-shrink: 0;
  font-size: 1.25rem;
  grid-area: learner;
  margin: 0;

  div {
    display: flex;

    a {
      color: theme.font;
      text-decoration: none;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      white-space: nowrap;
    }

    li:after {
      content: ", ";
      white-space: pre;
    }

    li:last-child:after {
      content: normal;
    }
  }

  @media only screen and ${theme.notLargeDevice} {
    display: none;
  }
`;

export const SupportUser = (props) =>{
  const
  { user, isAuthenticated, loading } = useFirebaseAuth(),
  { magento: { url } } = useEnvironment();

return (
  <>
    <StyledWelcome>
      { loading &&
        <a href='#'>
          Loading user...
        </a>
      }
      { !loading && isAuthenticated &&
        <span>
          Welcome to the Support Dashboard{' '}
          <a href={`${url}/customer/account/edit`}>
            {(user && user.displayName) || 'Support User'}
          </a>
        </span>
      }
      { !loading && !isAuthenticated &&
        <a href='#'>
          Unauthorized access
        </a>
      }
    </StyledWelcome>
    <StyledLearner>
      <div>
        <strong>
          <b>User Profile:</b>
        </strong>
      </div>
      { loading &&
        <p>
          Loading user...
        </p>
      }
      { !loading && isAuthenticated &&
        <p>
          { (user &&
           [
             user.email,
             user.displayName
           ].filter(d => d).join(", ")
          ) || 'Support User'}
        </p>
      }
      { !loading && !isAuthenticated &&
        <p>
          Unauthorized access
        </p>
      }

    </StyledLearner>
  </>
);
}

export default SupportUser;
