import { ILearner } from '@lawcpd/learner/shared/data';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { useFirebaseAuth } from 'support-provider';
import { useEffect, useState } from 'react';

interface LearnerAccountInformationProps {
  learner: ILearner,
}

export const LearnerAccountInformation = (props: LearnerAccountInformationProps) => {
  const
    { learner } = props,
    {  metadata, ids } = learner,
    { user } = useFirebaseAuth(),
    { cookiesDomain, mycpd: { api } } = useEnvironment(),
    [ primaryAccountInfo, setPrimaryAccountInfo ] = useState<ILearner>(),
    [ secondaryAccountsInfo, setSecondaryAccountsInfo ] = useState<ILearner[]>([]),
    [ isPrimaryAccountInfoError, setIsPrimaryAccountInfoError ] = useState(false),
    [ isPrimaryAccountInfoLoading, setIsPrimaryAccountInfoLoading ] = useState(true),
    [ isSecondaryAccountsInfoError, setIsSecondaryAccountsInfoError ] = useState(false),
    [ isSecondaryAccountsInfoLoading, setIsSecondaryAccountsInfoLoading ] = useState(true);

  const getPrimaryAccount = () => {
    const id = ids.lawcpd;
    if(!id) {
      setIsPrimaryAccountInfoLoading(false);
      return;
    }

    const endPoint = `${api}/api/learner/${id}`;

    user.getIdToken()
    .then((token) => {
      const init = { headers: { Authorization: `Bearer ${token}` } };
      document.cookie = 'fbt=' + token + '; domain=' + cookiesDomain + '; path=/; samesite=lax; secure';

      return fetch(endPoint, init);
    })
    .then(_ => _.json())
    .then((res: Record<string, ILearner>[]) => {
      const primary = Object.values(res[0]);
      setPrimaryAccountInfo(primary[0]);
      setIsPrimaryAccountInfoLoading(false);
    })
    .catch(e => {
      console.error(e);
      setIsPrimaryAccountInfoError(true);
      setIsPrimaryAccountInfoLoading(false);
    })
  }

  const getSecondaryAccount = () => {
    if(!metadata.merged) {
      setIsSecondaryAccountsInfoLoading(false);
      return;
    }

    const
      ids = metadata.merged.map((l) => l.id),
      endPoint = (id: string) => `${api}/api/learner/${id}`;

    user.getIdToken()
    .then((token) => {
      const
        init = { headers: { Authorization: `Bearer ${token}` } },
        requests = ids.map((id) => {
          return fetch(endPoint(id), init);
        });

      document.cookie = 'fbt=' + token + '; domain=' + cookiesDomain + '; path=/; samesite=lax; secure';

      return Promise.all(requests);
    })
    .then(async (responses) => {
      const formatResponses = responses.map((r) => r.json());

      return (await Promise.all(formatResponses)).reduce((p, c) => {
        return p.concat(c);
      },[])
    })
    .then((formatResponses: Record<string, ILearner>[]) => {
      const learners = formatResponses.map((fr) => Object.values(fr)[0]);

      setSecondaryAccountsInfo(learners);
      setIsSecondaryAccountsInfoLoading(false);
    })
    .catch((e) => {
      console.log(e);
      setIsSecondaryAccountsInfoLoading(false);
      setIsSecondaryAccountsInfoError(true);
    })
  }

  const getPrimaryAccountDetails = () => {
    const { givenName, familyName, email } = primaryAccountInfo.data;
    return(
      <li><b>Parent Account: </b>{`${givenName} ${familyName} (${email})`}</li>
    )
  }

  const getSecondaryAccountDetails = () => {
    return(
      <li><b>Sub Accounts: </b>
      <ul>
      {secondaryAccountsInfo.map((l, i) => {
        const { givenName, familyName, email } = l.data;

        return (
          <li key={i}>
            {`${givenName} ${familyName} (${email})`}
          </li>
        );
      })}
      </ul>
      </li>
    )
  }

  useEffect(() => {
    if(user){
      getPrimaryAccount();
    }
  },[user])

  return(
    <>
      { (isPrimaryAccountInfoError || isSecondaryAccountsInfoError) &&
        <li>An error occured while retrieving the sub account information.</li>
      }
      { (isPrimaryAccountInfoLoading /* && isSecondaryAccountsInfoLoading */) &&
        <li>Retrieving sub account information...</li>
      }
      { !(isPrimaryAccountInfoError || isSecondaryAccountsInfoError) &&
        !(isPrimaryAccountInfoLoading /* && isSecondaryAccountsInfoLoading */) &&
        <>
        { primaryAccountInfo && getPrimaryAccountDetails() }
        { secondaryAccountsInfo.length > 0 && getSecondaryAccountDetails() }
        </>
      }

    </>
  )
}

export default LearnerAccountInformation;
