import SelectSearch, { Props as SelectSearchProps } from 'react-select';
import styled from 'styled-components';

export const StyledDropDownList = styled.div`
  position: relative;
  display: inline-block;

  .dropdown-input {
    padding: 8px;
    width: 200px;
  }

  .dropdown-list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  .dropdown-list li {
    padding: 8px;
    cursor: pointer;
  }

  dropdown-list li:hover {
    background-color: #f0f0f0;
  }
`;

export const StyledDropDownSelection = styled.select`
text-overflow: ellipsis;
flex: 0 0 200px;
width: 200px;
`;

type Primitive = number | string;

export type OptionsType<Type> = { value: Type, label: Primitive } & Record<string, Primitive>;
export interface IDropDownSelect<Type> extends SelectSearchProps {
  options: OptionsType<Type>[] | Type[];
  loading: boolean;
  startIdx?: number;
}

const primitiveToOptions = (p: Primitive[] | OptionsType<Primitive>[]): OptionsType<Primitive>[] => {
  return p.map((d) => {
    if(d.value && d.label) return d;
    else return { value: d, label: d }
  });
}

export const DropDownSelect = (props: IDropDownSelect<Primitive>) => {
  const
    { options, startIdx, loading, ...rest } = props;

  if(!options || options.length === 0 && loading === undefined){
    return;
  }

  const defaults = primitiveToOptions(options);
  return (
    <SelectSearch
      closeMenuOnSelect={true}
      options={defaults}
      isDisabled={loading}
      { ...(defaults[startIdx] ? { defaultValue: defaults[startIdx] } : {} ) }
      {...rest}
    />
  )
}

export default DropDownSelect;
