import { useEffect, useState } from 'react';
import { ModalBackdrop, ModalPromptTypes } from '@lawcpd/learner/shared/ui';
import { AnnotatedRegistration, getCpdYear, ILearner } from '@lawcpd/learner/shared/data';
import {  LearnersProvider, useEnvironment } from '@lawcpd/learner/shared/provider';
import {  useFirebaseAuth } from 'support-provider';
import LearnerTable, { LearnerTableHeaders } from './data/learner-table';
import { LearnersComponentSelection, LearnersRemoveComponentSelection, StyledLearnerFormWrapper } from './data/assign-courses';
import { AssignFromLearnersComponentSelection, RegistrationFromLearner } from './data/assign-from-learners';

type PostBodies = { body: any, url: string }
type GetDeleteBodies = { url: string };

type RequestBodies = {
  GET?: GetDeleteBodies[],
  PUT?: PostBodies[],
  POST?: PostBodies[],
  DELETE?: GetDeleteBodies[]
};

/**
 * Creates a payload body for the endpoint
 * for updating registrations.
 *
 * @param url The url for the updating of registrations
 * @param registrations The registrations to update
 * @returns An array of PUT payload request which contains the registrations ids
 * to update alongside the registrations new learner to which it will be assigned to
 */
const createUpdateAllocRequestPayload = (url: string, registrations: RegistrationFromLearner[]) => {
/** Transfer
   * /registrations/update
   * learnerId
   * id
   * courseId
   */
  if(registrations.length === 0) { return []; }

  const createUpdatePayload: any = { data: [], group: 'registration', name: 'update'};
  for(const entry of registrations){
    createUpdatePayload.data.push({
      id: entry.registration,
      learnerId: entry.newLearner
    });
  }

  return [{
    body:{
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify(createUpdatePayload)
    },
    url: url
  }]
}

/**
 * Creates a payload body for the endpoint
 * for allocating registrations.
 *
 * @param url The url for the allocations of courses
 * @param allocations The allocations to be added per learner
 * @returns An array of POST payload request which contains the course ids and learner ids
 */
const createAllocRequestPayload = (url: string, allocations: Record<string, string[]>) => {
  if(Object.keys(allocations).length === 0) { return []; }

  const createPayload: any = { data: [], group: 'registration', name: 'create' };
  for(const [learnerId, courseIds] of Object.entries(allocations)){
    createPayload.data.push({
      courseIds: courseIds,
      learnerIds: [learnerId]
    });
  }

  return [{
    body:{
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(createPayload)
    },
    url: url
  }]
}

/**
 * Creates multiple DELETE requests depending on the total
 * length of the total registrations needed to be deallocated
 *
 * @param url The url for deallocation of courses
 * @param deallocations The deallocation to be processed or to be removed for each learners
 * @returns An array of DELETE url requests partitioned to decent partitioned length of registration
 * ids to delete
 */
const createDeallocRequestPayload = (url: string, deallocations: Record<string, string[]>) => {
  if(Object.keys(deallocations).length === 0) { return [] }

  const
    urlLimit = 1700,
    idsToUse: string[][] = [],
    allIdsToDelete: string[] = Object.values(deallocations).reduce((all, regs) =>{
      return [...all, ...regs];
    },[]);

  let strt = 0;
  for(let ctr = 0, i = 0; i< allIdsToDelete.length; i++){
    const
      id = allIdsToDelete[i],
      sum = ctr + id.length + 1;
    if(sum >= urlLimit){
      idsToUse.push(allIdsToDelete.slice(strt, i +1));
      strt = i+1;
    }
  }
  idsToUse.push(allIdsToDelete.slice(strt));

  const deleteRequests = idsToUse.map((d) => {
    return `${url}${d.join(',')}`;
  });

  return deleteRequests.map((d) =>{
    return {
      url: d
    };
  });
}

interface AllocateDeallocateProps {
  selectedLearners: ILearner[];
  assignType: string;
  onCancel: () => void;
}

export const AllocateDeallocate = (props: AllocateDeallocateProps) => {
  const
    cpdYear = getCpdYear(new Date),
    { cookiesDomain, mycpd: { api } } = useEnvironment(),
    { user } = useFirebaseAuth(),
    { selectedLearners, onCancel, assignType } = props,
    [ error, setError ] = useState(false),
    [ sourceLearners, setSourceLearners ] = useState<ILearner[]>([]),
    [ registrationsLoading, setRegistrationsLoading ] = useState(false),
    [ isSearchLearnersFrom, setIsSearchLearnersFrom ] = useState(false),
    [ registrationsLearners, setRegistrationsLearners ] = useState<AnnotatedRegistration[]>([]),
    [ registrationsFromLearners, setRegistrationsFromLearners ] = useState<AnnotatedRegistration[]>([]),
    // Registration ID for deallocations; Course ID for allocations. e.g. { <learnerId> : <courseId> | <registrationId> }
    [ allocationsDeallocations, setAllocationsDeallocations ] = useState<Record<string, string[]>>({}),
    [ allocDeallocFromLearners, setAllocDeallocFromLearners ] = useState<RegistrationFromLearner[]>([]);


  let modalContentConfig:{
    directlyAssignTxt?: string,
    directlyAssignHandler?: (i: string | number, c: string[]) => any,
    assignFromOtherTxt?: string,
    assignFromOtherHandler?: (c: any[]) => any
  } = {};

  // =================== HANDLERS =================== //
  //#region
  const toggleSearchLearnersFrom = (b: boolean) => {
    setIsSearchLearnersFrom(b);
  }

  const addSourceLearner = (learner) => {
    const
      sourceLearnerIds = sourceLearners.map((d) => d.id),
      selectedIds = selectedLearners.map((d) => d.id);

    if(!learner
      || sourceLearnerIds.includes(learner.id)
      || selectedIds.includes(learner.id)) return;
    setSourceLearners([...sourceLearners, learner])
  }

  const removeSourceLearner = (idx: number) => {
    const learner = sourceLearners[idx];
    if(!learner) return;
    const
      beforeElements = sourceLearners.slice(0,idx),
      afterElements = sourceLearners.slice(idx+1);

      setSourceLearners([...beforeElements, ...afterElements])
  }

  const allocateFromLearnerToLearnerHandler = (reg: RegistrationFromLearner[]) => {
    setAllocDeallocFromLearners(reg);
  }

  const allocateToLearnerHandler = (id: string | number, courses: string[]) => {
    setAllocationsDeallocations(Object.assign({}, allocationsDeallocations, { [id]:courses }))
  }

  const deallocateFromLearnerToLearnerHandler = (reg: RegistrationFromLearner[]) => {
    setAllocDeallocFromLearners(reg);
  }
  const deallocateToLearnerHandler = (id: string | number, courses: string[]) => {
    setAllocationsDeallocations(Object.assign({}, allocationsDeallocations, { [id]:courses }))
  }

  const registrationsFromLearnersHandler = () => {
    const srcIds = sourceLearners.map((d) => d.id);

    if(srcIds.length === 0) return;
    setRegistrationsLoading(true);
    const registrationsApi = `${api}/api/registration?ids=${srcIds.join()}`;
    user.getIdToken()
    .then((token) => {
      document.cookie = 'fbt=' + token + '; domain=' + cookiesDomain + '; path=/; samesite=lax; secure';
      const init = { headers: { Authorization: `Bearer ${token}` } };
      return fetch(registrationsApi, init);
    })
    .then( _ => _.json())
    .then( res => {
      setRegistrationsLoading(false);
      return {
        ...res[0]
      }
    })
    .then((registrations: Record<string, AnnotatedRegistration>) => {
      const
        regVals = Object.values(registrations),
        thisCpdYearRegistrations = regVals.filter((r) => {
          return r.cpdYear === cpdYear || !r.cpdYear
        })

      setRegistrationsFromLearners(thisCpdYearRegistrations)
    })
    .catch(e => {
      setError(true);
      return e;
    });
  }

  const registrationsLearnersHandler = () => {
    const srcIds = selectedLearners.map((d) => d.id)

    if(srcIds.length === 0) return;

    setRegistrationsLoading(true);
    const registrationsApi = `${api}/api/registration?ids=${srcIds.join()}`;
    user.getIdToken()
    .then((token) => {
      document.cookie = 'fbt=' + token + '; domain=' + cookiesDomain + '; path=/; samesite=lax; secure';
      const init = { headers: { Authorization: `Bearer ${token}` } };
      return fetch(registrationsApi, init);
    })
    .then( _ => _.json())
    .then( res => {
      setRegistrationsLoading(false);
      return {
        ...res[0]
      }
    })
    .then((registrations: Record<string, AnnotatedRegistration>) => {
      const
        regVals = Object.values(registrations),
        thisCpdYearRegistrations = regVals.filter((r) => {
          return r.cpdYear === cpdYear || !r.cpdYear
        })

      setRegistrationsLearners(thisCpdYearRegistrations)
    })
    .catch(e => {
      setError(true);
      return e;
    })
  };

  const confirmBtnHandler = () => {
    const
      registrationApiUrl = `${api}/api/registration`,
      requestBodies:RequestBodies  = {}

    let
      helpText = '',
      requests: Promise<Response>[] = [];

    if(assignType === 'allocate'){
      const
        allocUrl = registrationApiUrl,
        payload = createAllocRequestPayload(allocUrl, allocationsDeallocations);

      helpText = 'allocating';

      if(payload.length > 0) {
        requestBodies.POST = requestBodies.POST ? [...requestBodies.POST, ...payload] : payload;
      }
    }
    else if(assignType === 'deallocate'){
      const
        deallocUrl = `${registrationApiUrl}?ids=`,
        payload = createDeallocRequestPayload(deallocUrl, allocationsDeallocations);

      helpText = 'deallocating';

      if(payload.length > 0) {
        requestBodies.DELETE = requestBodies.DELETE ? [...requestBodies.DELETE, ...payload] : payload;
      }
    }
    else {
      onCancel();
    }

    const
      updateUrl = registrationApiUrl,
      putPayload = createUpdateAllocRequestPayload(updateUrl, allocDeallocFromLearners);

    requestBodies.PUT = requestBodies.PUT ? [...requestBodies.PUT, ...putPayload] : putPayload;


    user.getIdToken()
    .then((token) => {
      const { PUT: putRequests, POST: postRequests, DELETE: deleteRequests } = requestBodies;

      // POST
      if(postRequests){
        requests = [...requests, ...postRequests.map((r) => {
          return fetch(r.url, {
            ...r.body,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
        })];
      }

      // DELETE
      if(deleteRequests){
        requests = [...requests, ...deleteRequests.map((r) => {
          return fetch(r.url, {
            method: 'DELETE',
            mode: 'cors' as RequestMode,
            headers: { Authorization: `Bearer ${token}` }
          });
        })];
      }

      // PUT
      if(putRequests){
        requests = [...requests, ...putRequests.map((r) => {
          return fetch(r.url, {
            ...r.body,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
        })];
      }

      return requests;
    })
    .then(async (res) => {
      await Promise.all(res);
      alert(`The ${helpText} of courses has been successful`);
      onCancel();
    })
    .catch((e) => {
      alert(`An error occured while ${helpText} courses: ${e.message}`);
      onCancel();
    })
  }
  //#endregion

  switch(assignType){
    case 'allocate':
      modalContentConfig = {
        directlyAssignTxt: 'Allocate course directly',
        directlyAssignHandler: allocateToLearnerHandler,
        assignFromOtherTxt: 'Allocate course from another learner',
        assignFromOtherHandler: allocateFromLearnerToLearnerHandler
      }
      break;
    case 'deallocate':
      modalContentConfig = {
        directlyAssignTxt: 'Deallocate course directly',
        directlyAssignHandler: deallocateToLearnerHandler,
        assignFromOtherTxt: 'Deallocate course from another learner',
        assignFromOtherHandler: deallocateFromLearnerToLearnerHandler
      }
      break;
    default:
      return;
  }

  useEffect(() =>{
    if(user){
      if(assignType === 'allocate' && registrationsFromLearners.length === 0){
        // Get registrations of currently sourced learners
        registrationsFromLearnersHandler();
      }
      else if(assignType === 'deallocate' && registrationsLearners.length === 0){
        // Get registrations of currently selected learners
        registrationsLearnersHandler();
      }
    }
  }, [user, registrationsLearners.length, registrationsFromLearners.length])

  return (
    <div>
      {!isSearchLearnersFrom && assignType === 'allocate' &&
        <ModalBackdrop
          backdropId='backdrop-root'
          overlayId='overlay-root'
          type={('confirm' as ModalPromptTypes)}
          title='Allocate courses'
          onConfirm={confirmBtnHandler}
          onCancel={onCancel}
          onClose={onCancel}
          confirmText='Save'
          cancelText='Cancel'
          {...props}
          >
          <h2>Assign new courses to users</h2>
          <hr/>
          <StyledLearnerFormWrapper>
          { selectedLearners.map((l, idx) =>
            <LearnersComponentSelection
              key = {l.id || idx}
              learnerId = {l.id || idx}
              learnerName = {l.data.givenName}
              learnerEmail = {l.data.email}
              assignHandler = {modalContentConfig.directlyAssignHandler}
              assignmentsFromLearners = {
                allocDeallocFromLearners
                  .filter((d) => d.newLearner === l.id)
                  .map((d) => d.courseId)
              }
            />)}
          </StyledLearnerFormWrapper>
          <h2>Alternatively assign courses based from existing learners</h2>
          <hr/>
          {!registrationsLoading && !error &&
            <AssignFromLearnersComponentSelection
              targetLearners = {selectedLearners}
              sourceLearners = {sourceLearners}
              directAssignments = {allocationsDeallocations}
              removeSourceLearnerHandler = {removeSourceLearner}
              toggleAddSourceLearnerWindow = {toggleSearchLearnersFrom}
              assignmentType = {'allocate'}
              assignHandler = {modalContentConfig.assignFromOtherHandler}
              registrations = {registrationsFromLearners}
              />
          }
          {registrationsLoading &&
            <b>Loading...</b>
          }
        </ModalBackdrop>
      }
      {!isSearchLearnersFrom && assignType === 'deallocate' &&
        <ModalBackdrop
          backdropId='backdrop-root'
          overlayId='overlay-root'
          type={('confirm' as ModalPromptTypes)}
          title='Deallocate courses'
          onConfirm={confirmBtnHandler}
          onClose={onCancel}
          onCancel={onCancel}
          confirmText='Save'
          cancelText='Cancel'
          {...props}
          >
          <h2>Remove courses from users</h2>
          <hr/>
          <StyledLearnerFormWrapper>
            {!registrationsLoading && !error && registrationsLearners.length === 0 &&
              <b>No courses currently assigned in the current CPD year for the selected learners.</b>
            }
            {!registrationsLoading && !error && registrationsLearners.length != 0 && selectedLearners.map((l, idx) =>
              <LearnersRemoveComponentSelection
                key = {l.id || idx}
                learnerId = {l.id || idx}
                learnerName = {l.data.givenName}
                learnerEmail = {l.data.email}
                learnerRegistrations = {registrationsLearners}
                assignHandler= {modalContentConfig.directlyAssignHandler}
                assignmentsFromLearners = {
                  allocDeallocFromLearners
                    .filter((d) => d.newLearner === l.id)
                    .map((d) => d.courseId)
                }
            />)}
            {registrationsLoading &&
              <b>Loading...</b>
            }
          </StyledLearnerFormWrapper>
          <h2>Alternatively re-assign courses to existing learners</h2>
          <hr/>
          {!registrationsLoading && !error && registrationsLearners.length === 0 &&
            <b>No courses currently assigned in the current CPD year for the selected learners.</b>
          }
          {!registrationsLoading && !error &&
            <AssignFromLearnersComponentSelection
              targetLearners = {selectedLearners}
              sourceLearners = {sourceLearners}
              directAssignments = {allocationsDeallocations}
              removeSourceLearnerHandler = {removeSourceLearner}
              toggleAddSourceLearnerWindow = {toggleSearchLearnersFrom}
              assignmentType = {assignType}
              assignHandler = {modalContentConfig.assignFromOtherHandler}
              registrations = {registrationsLearners}
              />
          }
          {registrationsLoading &&
            <b>Loading...</b>
          }
        </ModalBackdrop>
      }
      {isSearchLearnersFrom &&
        <LearnersProvider>
          <ModalBackdrop
            backdropId='backdrop-root'
            overlayId='overlay-root'
            type={('confirm' as ModalPromptTypes)}
            onClose={() => {
              setSourceLearners([]);
              toggleSearchLearnersFrom(false)}
            }
            onCancel={() => {
              setSourceLearners([]);
              toggleSearchLearnersFrom(false)}
            }
            onConfirm={() => {
              if(user){
                registrationsFromLearnersHandler();
              }
              toggleSearchLearnersFrom(false)
            }}
            confirmText='Use selected learners'
            cancelText='Cancel'
            >
            <LearnerTable
              learnerHeaders={[
                LearnerTableHeaders.Name,
                LearnerTableHeaders.Email,
                LearnerTableHeaders.Information
              ]}
              overrideSelectedLearner={{
                selectedLearner: () => sourceLearners,
                addSelectedLearner: addSourceLearner,
                removeSelectedLearner: removeSourceLearner
              }}
            />
          </ModalBackdrop>
        </LearnersProvider>
      }
    </div>
  )
}
