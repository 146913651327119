import ReactDOM from 'react-dom/client';
import {
  CoursesProviderFirebase,
  EnvironmentProvider,
  LearnersProvider,
} from '@lawcpd/learner/shared/provider';
import { EventLogsProvider, FirebaseAuthProvider } from 'support-provider';
import { environment } from './environments/environment';

import App from './app/app';
import { FeatureFlagsProvider } from '@lawcpd/feature-flags/provider';
import { BrowserRouter } from 'react-router-dom';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <EnvironmentProvider environment={environment}>
    <FeatureFlagsProvider useLearnerFF={false}>
      <FirebaseAuthProvider>
        <EventLogsProvider>
          <CoursesProviderFirebase>
            <LearnersProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </LearnersProvider>
          </CoursesProviderFirebase>
        </EventLogsProvider>
      </FirebaseAuthProvider>
    </FeatureFlagsProvider>
  </EnvironmentProvider>
);
