import { useEventLogs } from 'support-provider';
import { Alert, BackdropSpinner, theme } from '@lawcpd/learner/shared/ui';
import styled from 'styled-components';
import { EventsSearch } from './events-search';
import TextToggle from '../ui/text-toggle';
import { useState } from 'react';

const StyledTable = styled.div`
position: relative;

.events-table {
  border: none;
  border-right: solid 1px #DDEFEF;
  border-collapse: separate;
  border-spacing: 0;
  font: normal 13px Arial, sans-serif;
}

.events-table thead th {
  background-color: #DDEFEF;
  border: none;
  color: #336B6B;
  padding: 10px;
  text-align: left;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
}

.events-table tbody td {
  border-bottom: solid 1px #DDEFEF;
  color: #333;
  padding: 10px;
  text-shadow: 1px 1px 1px #fff;
  white-space: pre-line;
  height: 30px;
  vertical-align: top;
  word-break: break-word;
}

.events-table tbody tr {
  cursor: pointer;
}

.events-table tbody tr:hover {
  background-color: #CCFFCC !important;
}

.tablecontainer {
  margin-left: 141px;
  overflow-x: scroll;
  overflow-y: visible;
  padding-bottom: 5px;
  width:70vw;
}

@media only screen and ${theme.notLargeDevice} {
  .tablecontainer {
    width:90vw;
  }
}
`;


export const EventsTable = (props) => {
  const
    {
      eventLogs,
      error,
      loading,
    } = useEventLogs(),
    { changeNotesHandler, ...rest }: {
      changeNotesHandler: (s:string, uid:string, eid:string) => any
    } = props,
    [ showCopyAlert, setCopyAlert ] = useState(false);

  const stickyfy = (elem, txt, idx) => {
    const isSticky = idx === 0 ? 'sticky-col' : '';

    switch(elem){
      case 'th':
        return (
          <th className={isSticky} key={idx}>{txt}</th>
        );
      case 'td':
        return (
          <td className={isSticky} key={idx}>{txt}</td>
        );
      default:
        return;
    }
  }

  const tableColumns = () => {
    const cols = [
      'User',
      'Date',
      'Type',
      'Learner',
      'Course',
      'Status',
      'Notes',
      'Event ID',
    ];

    return (
      <thead>
        <tr>
          {cols.map((c, i) => { return stickyfy('th', c, i); })}
        </tr>
      </thead>
    );
  }

  const formatToLawCPDTicket = (ticketNumber: string) => {
    if(!ticketNumber) return;
    const ticket = ticketNumber.match(/\d+/);

    if(ticket === null || !ticket){
      return ticketNumber
    }

    return (
      <span>
        {ticket.length > 1 && ticketNumber}
        {ticket.length === 1 &&
          <a href={`https://help.lawcpd.com.au/a/tickets/${ticket[0]}`}>{ticketNumber}</a>
        }
      </span>
    );
  }

  const copyToClipBoard = (s: string) => {
    navigator.clipboard.writeText(s);
    setCopyAlert(true);
    setTimeout(() => setCopyAlert(false), 1000);
  }

  const tableRows = () => {
    return (
      <tbody className='events-table-data'>
        {eventLogs.map((d, i) => {
          let learner = '', course = '';

          // Check for learner, use email, if not its id
          if(d.learnerId){
            if(d.learner && d.learner.data && d.learner.data.email){
              learner = d.learner.data.email;
            }
            else{ learner = d.learnerId; }
          }

          // Check for course, use courrse name, if not its id
          if(d.courseId){
            if(d.course && d.course.data && d.course.data.courseName){
              course = d.course.data.courseName;
            }
            else{ course = d.courseId; }
          }

          return (
            <tr key={i}>
              {stickyfy('td', d.userId, 0)}
              {stickyfy('td', d.date, i+2)}
              {stickyfy('td', d.type, i+3)}
              {stickyfy('td', learner, i+4)}
              {stickyfy('td', course, i+5)}
              {stickyfy('td', d.statusMessage, i+6)}
              <td>
                <TextToggle
                  text={d.notes}
                  textChangeCb={(s) => changeNotesHandler(s, d.userId, d.eventId)}
                  textRenderCb={(s) => formatToLawCPDTicket(s)}
                />
              </td>
              <td>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-copy"
                  width="24" height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  onClick={() => copyToClipBoard(d.eventId)}
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <rect x="8" y="8" width="12" height="12" rx="2"></rect>
                    <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
                </svg>
                <span>{d.eventId.slice(0,6)}</span>
              </td>
            </tr>
          )
        })}
      </tbody>
    )
  }


  return (
    <StyledTable>
      {showCopyAlert &&
        <Alert overlayId='overlay-root'>
          <p>Copied to clipboard.</p>
        </Alert>
      }
      {loading &&
        <BackdropSpinner backdropId='backdrop-root' />
      }
      <EventsSearch
        {...props}
        />
      <div className='tablecontainer'>
        <table className='events-table'>
          {tableColumns()}
          {!loading && !error && tableRows()}
        </table>
        {!loading && !error && eventLogs.length === 0 &&
          <h1>No data available</h1>
        }
        {!loading && error &&
          <h1>An error occured while retrieving support events</h1>
        }
      </div>
    </StyledTable>
  )
}
