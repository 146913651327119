import React, { useState } from "react";
import styled from "styled-components";


const StyledProgressBar = styled.div`
  background-color: #f1f1f1;
  color: white;

  .progress-bar{
    background-color: #f37021;
    text-align: center;
  }
`;

interface IProgress {
  progress: number;
}

export const ProgressBar = ({ progress }: IProgress) => {
  if( isNaN(+progress) || +progress < 0) return;

  const progressThreshold = progress <= 100 ? progress : 100;

  return (
    <StyledProgressBar>
      <div
        className='progress-bar'
        style={{ width: `${progressThreshold}%` }}
      >
        {`${progressThreshold}%`}
      </div>
    </StyledProgressBar>
  )
}

export default ProgressBar;
