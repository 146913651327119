import { useFeatureFlags } from '@lawcpd/feature-flags/provider';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { useFirebaseAuth } from 'support-provider';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { EventsTable } from './data/events-table';

export const EventLogs = (props) => {
  const
    { featureFlags, loading, error } = useFeatureFlags(),
    { cookiesDomain, mycpd: { api }, support: { api: supportApi } } = useEnvironment(),
    { user } = useFirebaseAuth(),
    [ endPoint, setEndPoint ] = useState<string>(api),
    [ eventChanges, setEventChanges ] = useState<Record<string, object>>({});

  useEffect(() => {
    if(!loading && !error){
      setEndPoint(featureFlags.SPLIT_OUT_SUPPORT_API_10126 ? supportApi : api);
    }
  }, [loading]);

  const explodeEventChanges = () => {
    const arrayedEventChanges: {
      eventId: string, userId: string
    }[] = [];

    /**
     *  uid
     *    -eid
     *      -any
     */
    for(const [uid, eids] of Object.entries(eventChanges)){
      if(Object.keys(eids).length === 0 || !uid) continue;

      for(const [eid, eventChange] of Object.entries(eids)){
        if(Object.keys(eventChange).length === 0 || !eid) continue;

        const entry = Object.assign({}, eventChange, {
          eventId: eid,
          userId: uid
        });

        arrayedEventChanges.push(entry);
      }
    }

    return arrayedEventChanges;
  }

  const applyEventChanges = () => {
    const eventChangesData = explodeEventChanges();
    if(!user || eventChangesData.length === 0) return;

    return user.getIdToken()
    .then((token) => {
      document.cookie = 'fbt=' + token + '; domain=' + cookiesDomain + '; path=/; samesite=lax; secure';
      const
        rb = {
          data: eventChangesData,
          group: 'support',
          name: 'update'
        },
        init = {
          body: JSON.stringify(rb),
          mode: 'cors' as RequestMode,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        };
      return fetch(`${endPoint}/api/support/event`, init);
    })
    .then( _ => _.json())
    .then( _ => setEventChanges({}))
    .catch(e => alert(e));
  }

  const changeNotesHandler = (s: string, uid: string, eid: string, ) => {
    const newChanges = Object.assign({}, eventChanges);
    if(!newChanges[uid]) newChanges[uid] = {};
    if(!newChanges[uid][eid]) newChanges[uid][eid] = {};

    const oldChanges = newChanges[uid][eid];

    newChanges[uid][eid] = Object.assign(oldChanges, { notes: s });

    setEventChanges(newChanges);
  }

  return (
    <EventsTable
      changeNotesHandler={changeNotesHandler}
      getEventChanges={eventChanges}
      applyEventChanges={applyEventChanges}
      />
  )
}
