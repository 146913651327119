import React, { SetStateAction, useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import styled from 'styled-components';
import LearnerInfo from '../learner-info';
import LearnerSearch from './learner-search';
import DropDownSelect from '../ui/drop-down-select';
import SpanPill from '../ui/span-pill';
import { MergeLearners } from '../merge';
import { MasqueradeLearner } from '../learners/masquerade';
import { AllocateDeallocate } from '../course-allocate-deallocate';
import { TableCell, TableColumns } from '../ui/table';
import { ILearner, SearchString } from '@lawcpd/learner/shared/data';
import { Alert, BackdropSpinner, theme } from '@lawcpd/learner/shared/ui'
import { useEnvironment, useLearners } from '@lawcpd/learner/shared/provider';
import { useEventLogs } from 'support-provider';

const StyledTable = styled.div`
position: relative;

.learners-table {
  border: none;
  border-right: solid 1px #DDEFEF;
  border-collapse: separate;
  border-spacing: 0;
  font: normal 13px Arial, sans-serif;
}

.learners-table thead th {
  background-color: #DDEFEF;
  border: none;
  color: #336B6B;
  padding: 10px;
  text-align: left;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
}

.learners-table tbody td {
  border-bottom: solid 1px #DDEFEF;
  color: #333;
  padding: 10px;
  white-space: nowrap;
  height: 30px;
  vertical-align: middle;
}

.learners-table tbody td svg {
  display: inline-block;
  vertical-align: middle;
}

.learners-table tbody td p {
  display: inline;
}

.learners-table tbody td .btn {
  cursor: pointer;
}

.learners-table tbody tr:hover {
  background-color: #CCFFCC !important;
}

.learners-table tbody .secondary-account{
  color: white;
  background-color: #96EAFF;
}

.learners-table tbody .primary-account{
  color:white;
  background-color: #FF82E0;
}

.learners-table .registration-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.learners-table .registration-cell > * {
  width: max-content;
  margin-right: 5px;
}

.learners-table button, svg {
  cursor: pointer;
}

.learners-legend span {
  margin-right: 25px;
}

.learners-legend span svg {
  vertical-align: middle;
}

.learners-legend span .primary-account{
  fill: #FF82E0;
}

.learners-legend span .secondary-account{
  fill: #96EAFF;
}

.learners-legend span .selected-account{
  fill: #CCFFCC;
}

.tablecontainer {
  overflow-x: scroll;
  overflow-y: visible;
  padding-bottom: 5px;
  width:70vw;
}

@media only screen and ${theme.notLargeDevice} {
  .tablecontainer {
    width:90vw;
  }
}
`;

const StlyledController = styled.div`
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;

  button {
    margin-right: 10px;
    cursor: pointer;
  }

  .selected-pills {
    display: inline;
  }

  .selected-pills span {
    background-color: #CCFFCC;
    color: black;
  }

  .selected-pills button {
    margin-right:0px;
  }
`

export enum LearnerTableHeaders {
  Name = "Name",
  Email = "Email",
  Created = "Created",
  Magento = "Magento Edit Customer",
  Auth0 = "Auth0",
  Login = "Login Methods",
  Information = "Learner Information",
  Registration = "Registration",
  LearnerEventLogs = "Log",
  Masquerade = "Masqeurade Learner"
};

interface LearnerTableProps {
  learnerHeaders: [LearnerTableHeaders.Name, LearnerTableHeaders.Email, ...LearnerTableHeaders[]];
  overrideSelectedLearner? : {
    selectedLearner: () => ILearner[];
    addSelectedLearner: (l: ILearner) => void;
    removeSelectedLearner: (i: number) => void;
  };
}

// This serves so that the LearnerTable component is modular
// ===================== TABLE CELL COMPONENTS ===================== //
//#region
const LearnerName = (firstName: string, lastName: string) => {
  return(
    <td key={1}>
      <p>{`${firstName} ${lastName}`}</p>
    </td>
  )
};

const LearnerEmail = (email: string, copyHandler: (e: string) => void) => {
  return (
    <td key={2}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="btn"
        width="24" height="24"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        onClick={() => copyHandler(email)}
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <rect x="8" y="8" width="12" height="12" rx="2"></rect>
          <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
      </svg>
      <p>{email}</p>
    </td>
  );
}

const LearnerCreatedDate = (created: number) => {
  return TableCell('td', `**${new Date(created).toLocaleDateString()}** (${created})`, 3);
}

const LearnerMagentoLink = (domain: string, magentoId: number = null) => {
  const link = `https://${domain}/admin/customer/index/edit/id/${magentoId}`;

  return(
    <td key={4}>
      { magentoId &&
        <a href={link} target="_blank">
          <p>View in Magento</p>
        </a>
      }
      { !magentoId &&
        <p>No Magento Id</p>
      }
    </td>
  )
}

const LearnerAuth0Link = (tenant: string, auth0Id: string = null) => {
  const link = `https://manage.auth0.com/dashboard/au/${tenant}/users/${btoa(encodeURIComponent(auth0Id))}`;

  return(
    <td key={5}>
      { auth0Id &&
        <a href={link} target="_blank">
          <p>View in Auth0</p>
        </a>
      }
      { !auth0Id &&
        <p>No Auth0 Id</p>
      }
    </td>
  )
}

const LearnerIdentities = (mainLogin: string = null, providers: string[] = []) => {
  return(
    <td key={6}>
      { mainLogin && providers.length &&
        <p><b>{mainLogin}</b> {` (${providers.join(',')})`}</p>
      }
      { !mainLogin && (!providers || providers && !providers.length) &&
        <p>No Identities</p>
      }
    </td>
  )
}

const LearnerViewInformationBtn = (learner: ILearner, showInformationHandler: (l: SetStateAction<ILearner>) => void) => {
  return (
    <td key={7}>
      <button onClick={() => showInformationHandler(learner)}>View information</button>
    </td>
  )
}

const LearnerMasqueradeAsBtn = (learner: ILearner, masqueradeLearnerHandler: (l: SetStateAction<ILearner>) => void) => {
  return (
    <td key={7}>
      <button onClick={() => masqueradeLearnerHandler(learner)}>Masquerade as</button>
    </td>
  )
}

const LearnerRegistrationsBtn = (learner: ILearner, registrationHandler: (l: ILearner, ddlId: string) => void) => {
  const dropDownSelectId = `drop-down-${learner.id}`;

  return (
    <td key={8} className='registration-cell'>
      <DropDownSelect
        options={['Allocate', 'Deallocate']}
        startIdx={0}
        loading={false}
        name={dropDownSelectId}
        id={dropDownSelectId}
        isSearchable={false}

      />
      <button onClick={() => registrationHandler(learner, dropDownSelectId)}>Course</button>
    </td>
  )
}

const LearnerViewEventLogsLink = (learner: ILearner, setSearchTerms: (s: SearchString[]) => void) => {
  const email = learner.data && learner.data.email;
  return (
    <td key={9}>
      { email &&
        <a
          onClick={() => setSearchTerms([{ criteria: "learnerId", value: email }])}
          target="_blank"
          href={`/event-log/${email}`}
        >
          View Support Events
        </a>
      }
      { !email &&
        <p>No email to view logs</p>
      }

    </td>
  )
}
//#endregion

/**
 * The learner table component that shows learner data in a tabular format with various features.
 * @param props
 * @returns The LearnerTable component that shows list of learners alongside a search functionality.
 */
export const LearnerTable = (props: LearnerTableProps) => {
  const
    { learnerHeaders, overrideSelectedLearner, ...rest } = props,
    // If we don't have this header, by inference we don't want to deal any allocating or deallocating
    isUseAllocateDeallocate = learnerHeaders.includes(LearnerTableHeaders.Registration),
    { setSearchTerms: setEventLogsSearchTerms } = useEventLogs(),
    { learners, error, loading } = useLearners(),
    featureFlagCookie = Cookies.get('feature-flags'),
    flags = featureFlagCookie ? JSON.parse(featureFlagCookie) : {},
    { USE_MERGE_CASE_9702: useMerge } = flags,
    { domain, auth0: { tenant } } = useEnvironment(),
    [ showCopyAlert, setCopyAlert ] = useState(false),
    [ modalWindowType, setModalWindowType ] = useState(''),
    [ showMergeModal, setShowMergeModal ] = useState(false),
    [ showLearnerInfo, setShowLearnerInfo ] = useState<ILearner>(),
    [ showMasqueradeLearner, setShowMasqueradeLearner ] = useState<ILearner>(),
    [ showAllctDllctModal, setShowAllctDllctModal ] = useState(false),
    [ selectedLearner, setSelectedLearner ] = useState<ILearner>(), // Single learner
    [ selectedLearners, setSelectedLearners ] = useState<ILearner[]>([]); // Multiple

  const
    selectedLearnersToUse = overrideSelectedLearner
      ? overrideSelectedLearner.selectedLearner()
      : selectedLearners;

  // ===================== UI GENERATION ===================== //
  //#region
  const tableCellGenerationMapping: Record<LearnerTableHeaders, (l: ILearner) => JSX.Element> = {
    [LearnerTableHeaders.Name]: (l: ILearner) => LearnerName(l.data.givenName, l.data.familyName),
    [LearnerTableHeaders.Email]: (l: ILearner) => LearnerEmail(l.data.email, copyToClipBoard),
    [LearnerTableHeaders.Created]: (l: ILearner) => LearnerCreatedDate(l.metadata.created),
    [LearnerTableHeaders.Magento]: (l: ILearner) => LearnerMagentoLink(domain, l.ids.magento),
    [LearnerTableHeaders.Auth0]: (l: ILearner) => LearnerAuth0Link(tenant, l.ids.auth0),
    [LearnerTableHeaders.Login]: (l: ILearner) => {
      // Identity object results in { main_email: [<list of identities>] }
      // Hence the main email is identities[0][0], while main email provider is identities[0][1]
      const
        identities = l.metadata.identities && Object.entries(l.metadata.identities) || [],
        mainLogin = identities[0] ? identities[0][0] : null,
        providers = identities[0] ? identities[0][1] : null;

      return LearnerIdentities(mainLogin, providers)
    },
    [LearnerTableHeaders.Information]: (l: ILearner) => LearnerViewInformationBtn(l, setShowLearnerInfo),
    [LearnerTableHeaders.Registration]: (l: ILearner) => LearnerRegistrationsBtn(l, registrationLearnerBtnHandler),
    [LearnerTableHeaders.LearnerEventLogs]: (l: ILearner) => LearnerViewEventLogsLink(l, setEventLogsSearchTerms),
    [LearnerTableHeaders.Masquerade]: (l: ILearner) => LearnerMasqueradeAsBtn(l, setShowMasqueradeLearner)
  }


  const generateLearnerLegend = (legend: string) => {
    if(!legend) return;

    return (
      <span key={legend}>
        <svg xmlns="http://www.w3.org/2000/svg"
          className={`icon icon-learner-legend ${legend}`}
          width="24" height="24"
          viewBox="0 0 24 24"
          fill="none"
          strokeWidth="2"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          >
          <path stroke="none" fill="none" d="M0 0h24v24H0z"></path>
          <path d="M12 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
          <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
        </svg>
        <label>{legend}</label>
      </span>
    )
  };

  const tableRows = () => {
    return (
      <tbody className='learners-table-data'>
        {learners.map((d, i) => {
          const
            isPrimary = d.metadata.merged ? true : false,
            isSecondary = d.ids.lawcpd ? true : false,
            accountType = isPrimary
              ? 'primary-account'
              : isSecondary
              ? 'secondary-account'
              : '';

          return (
            <tr className={accountType} key={d.id}>
              <td key={i}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="btn"
                  onClick={ () => overrideSelectedLearner
                    ? overrideSelectedLearner.addSelectedLearner(d)
                    : addSelectedLearner(d)
                  }
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M3 12a9 9 0 1 0 18 0 9 9 0 0 0-18 0M9 12h6M12 9v6" />
                </svg>
              </td>
              { learnerHeaders.map((h) => tableCellGenerationMapping[h](d)) }
            </tr>
          )
        })}
      </tbody>
    )
  }

  const copyToClipBoard = (s: string) => {
    navigator.clipboard.writeText(s);
    setCopyAlert(true);
    setTimeout(() => setCopyAlert(false), 1000);
  }
  //#endregion

  // ===================== MODAL WINDOW HANDLER ===================== //
  //#region

  const addSelectedLearner = (learner: ILearner) => {
    const selectedLearnersIds = selectedLearners.map((l) => l.id);
    if(!learner || selectedLearnersIds.includes(learner.id)) return;
    setSelectedLearners([...selectedLearners, learner])
  }

  const removeSelectedLearner = (idx: number) => {
    const learner = selectedLearners[idx];
    if(!learner) return;
    const
      beforeElements = selectedLearners.slice(0,idx),
      afterElements = selectedLearners.slice(idx+1);

    setSelectedLearners([...beforeElements, ...afterElements])
  }

  const toggleModalHandler = (b:boolean) => {
    if(['allocate', 'deallocate'].includes(modalWindowType)){
      setShowAllctDllctModal(b);
    }
    else if(modalWindowType === 'merge'){
      setShowMergeModal(b);
    }
  }

  const controllerBtnHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const btnSrc = (e.target as HTMLButtonElement).innerText.toLocaleLowerCase();
    setModalWindowType(btnSrc)
  }

  const registrationLearnerBtnHandler = (learner: ILearner, ddlId: string) => {
    const
      dropDownSelect = document.getElementsByName(ddlId)[0] as HTMLInputElement,
      modalType = dropDownSelect.value.toLocaleLowerCase();

    setModalWindowType(modalType)
    setSelectedLearner(learner);
  }

  useEffect(() => {
    if(modalWindowType){
      toggleModalHandler(true);
    }
  }, [modalWindowType]);
  //#endregion

  // ===================== COMPONENT ===================== //
  return (
    <StyledTable>
      {showCopyAlert &&
        <Alert overlayId='overlay-root'>
          <p>Copied to clipboard.</p>
        </Alert>
      }
      {loading &&
        <BackdropSpinner backdropId='backdrop-root' />
      }
      {showLearnerInfo &&
        <LearnerInfo
          learner={showLearnerInfo}
          onCloseLearnerInfo={() => setShowLearnerInfo(undefined)}
          />
      }
      { showMasqueradeLearner &&
        <MasqueradeLearner
          learner={showMasqueradeLearner}
          onCancel={() => setShowMasqueradeLearner(undefined)}
          />
      }
      {showAllctDllctModal && isUseAllocateDeallocate &&
        <AllocateDeallocate
          onCancel={() => {
            toggleModalHandler(false);
            setModalWindowType('');
            setSelectedLearner(null);
          }}
          assignType={modalWindowType}
          selectedLearners={ selectedLearner ? [selectedLearner] : selectedLearnersToUse }
        />
      }
      {showMergeModal && isUseAllocateDeallocate &&
        <MergeLearners
          learners={ selectedLearner ? [selectedLearner] : selectedLearnersToUse }
          onCancel={() => {
            toggleModalHandler(false);
            setModalWindowType('');
            setSelectedLearner(null);
          }}
        />
      }
      <LearnerSearch
        {...rest}
        />
      <div className='tablecontainer'>
        <div className='learners-legend' >
          {['primary-account', 'secondary-account','selected-account'].map(generateLearnerLegend)}
        </div>
        <table className='learners-table'>
          {TableColumns(['Select', ...learnerHeaders])}
          {!loading && !error && tableRows()}
        </table>
        {!loading && !error && learners.length === 0 &&
          <h1>No data available</h1>
        }
        {!loading && error &&
          <h1>An error occured while retrieving learner data</h1>
        }
      </div>
      {selectedLearnersToUse.length > 0 &&
        <StlyledController id='selected-controller'>
          {isUseAllocateDeallocate &&
            <span>
              <button onClick={controllerBtnHandler}>Allocate</button>
              <button onClick={controllerBtnHandler}>Deallocate</button>
              <button
                onClick={controllerBtnHandler}
                disabled={selectedLearnersToUse.length < 2}
                style={{ display: useMerge ? 'inline' : 'none'}}
                >
                  Merge
              </button>
            </span>
          }
          <div className='selected-pills'>
            {selectedLearnersToUse.length > 0 &&
              <label>Selected learners:</label>
            }
            {selectedLearnersToUse.map((d, i) => { return (
                <SpanPill
                  key={i}
                  idx={i}
                  displayTxt={`${d.data.givenName} ${d.data.familyName}`}
                  loading={loading}
                  removeHandler={() => overrideSelectedLearner
                    ? overrideSelectedLearner.removeSelectedLearner(i)
                    : removeSelectedLearner(i)
                  }
                />)
            })}
          </div>
        </StlyledController>
      }
    </StyledTable>
  )
}

export default LearnerTable
