import Markdown from 'react-markdown';
import styled from 'styled-components';

const StyledTableHeader = styled.th`

`

export const TableCell = (elem, txt, idx = null) => {
  const
    isSticky = idx === 0 ? 'sticky-col' : '',
    keyProps = !idx && idx !== 0 ? {} : { key: idx };

  switch(elem){
    case 'th':
      return (
        <th className={isSticky} {...keyProps}>{txt}</th>
      );
    case 'td':
      return (
        <td className={isSticky} {...keyProps} >
          {txt}
        </td>
      );
    default:
      return;
  }
}

export const TableColumns = (cols: string[]) => {
  return (
    <thead>
      <tr>
        {cols.map((c, i) => { return TableCell('th', c, i); })}
      </tr>
    </thead>
  );
}
